'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  position: relative;\n  padding-left: 2em;\n  display: block;\n  color: white;\n  text-decoration: none;\n\n  &:before {\n    content: \'\';\n    width: 1em;\n    height: 1em;\n    border-radius: 50%;\n    background-color: transparent;\n    box-shadow: ', ';\n    position: absolute;\n    left: 0;\n    top: 50%;\n    transform: translateY(-50%);\n    display: inline-block;\n    margin-right: 10px;\n    left: 0px;\n  }\n\n  &.selected::before {\n    background-color: ', ';\n  }\n'], ['\n  position: relative;\n  padding-left: 2em;\n  display: block;\n  color: white;\n  text-decoration: none;\n\n  &:before {\n    content: \'\';\n    width: 1em;\n    height: 1em;\n    border-radius: 50%;\n    background-color: transparent;\n    box-shadow: ', ';\n    position: absolute;\n    left: 0;\n    top: 50%;\n    transform: translateY(-50%);\n    display: inline-block;\n    margin-right: 10px;\n    left: 0px;\n  }\n\n  &.selected::before {\n    background-color: ', ';\n  }\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ = require('..');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _theme = require('../theme');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

exports.default = (0, _styledComponents2.default)(_.Text)(_templateObject, function (props) {
  return props.active ? 'none' : 'inset 0 0 0 1px';
}, _theme.colors.green);