'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  position: relative;\n'], ['\n  position: relative;\n']),
    _templateObject2 = _taggedTemplateLiteral([''], ['']),
    _templateObject3 = _taggedTemplateLiteral(['\n  width: 100%;\n  padding-top: 5px;\n  position: absolute;\n  z-index: ', ';\n  opacity: ', ';\n  pointer-events: ', ';\n  background: white;\n  // temporary styled\n  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);\n'], ['\n  width: 100%;\n  padding-top: 5px;\n  position: absolute;\n  z-index: ', ';\n  opacity: ', ';\n  pointer-events: ', ';\n  background: white;\n  // temporary styled\n  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  position: relative;\n  padding: 0;\n  max-height: 205px;\n  overflow-y: scroll;\n  margin: 0;\n  list-style: none;\n'], ['\n  position: relative;\n  padding: 0;\n  max-height: 205px;\n  overflow-y: scroll;\n  margin: 0;\n  list-style: none;\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n  border-bottom: 1px solid ', ';\n  padding: 8px;\n  cursor: pointer;\n  &:hover {\n    color: ', ';\n  }\n  pointer-events: ', ';\n  background-color: ', ';\n'], ['\n  border-bottom: 1px solid ', ';\n  padding: 8px;\n  cursor: pointer;\n  &:hover {\n    color: ', ';\n  }\n  pointer-events: ', ';\n  background-color: ', ';\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ramda = require('ramda');

var _recompose = require('recompose');

var _ = require('..');

var _withToggle = require('../unstable/withToggle');

var _withToggle2 = _interopRequireDefault(_withToggle);

var _withClickOutside = require('../unstable/withClickOutside');

var _withClickOutside2 = _interopRequireDefault(_withClickOutside);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _util = require('../util');

var _theme = require('../theme');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SelectFilter = function (_React$Component) {
  _inherits(SelectFilter, _React$Component);

  function SelectFilter(props) {
    _classCallCheck(this, SelectFilter);

    var _this = _possibleConstructorReturn(this, (SelectFilter.__proto__ || Object.getPrototypeOf(SelectFilter)).call(this, props));

    _this.state = {
      options: props.options,
      value: props.valueKey ? props[props.valueKey] : props.value
    };
    return _this;
  }

  _createClass(SelectFilter, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      var _props = this.props,
          options = _props.options,
          value = _props.value,
          valueKey = _props.valueKey;


      if (prevProps.options !== options) {
        this.setState({ options: options });
      }

      if (prevProps.value !== value || prevProps.valueKey !== valueKey) {
        this.setState({ value: valueKey ? this.props[valueKey] : value });
      }
    }
  }, {
    key: 'handleSelect',
    value: function handleSelect(val) {
      var disabledInnerState = this.props.disabledInnerState;

      var newVal = this.props.valueKey ? val[this.props.valueKey] : val.value;
      this.props.onChange(newVal);
      this.props.toggle();
      if (!disabledInnerState) {
        this.setState({
          value: newVal
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _props2 = this.props,
          toggle = _props2.toggle,
          open = _props2.open,
          handleSelect = _props2.handleSelect,
          handleChange = _props2.handleChange,
          onRef = _props2.onRef,
          onWrapperBlur = _props2.onWrapperBlur,
          placeholder = _props2.placeholder,
          valueKey = _props2.valueKey,
          labelKey = _props2.labelKey,
          status = _props2.status,
          required = _props2.required,
          onChange = _props2.onChange;
      var _state = this.state,
          options = _state.options,
          value = _state.value;

      var selectProps = (0, _ramda.omit)(['placeholder'], this.props);

      return React.createElement(
        Select,
        { innerRef: onRef },
        React.createElement(
          SelectCore,
          _extends({}, selectProps, {
            required: required,
            onMouseDown: toggle,
            isFocus: open,
            onChange: onChange,
            value: value || placeholder }),
          placeholder ? React.createElement(
            'option',
            { disabled: true },
            placeholder
          ) : null,
          options.map(function (option, index) {
            return React.createElement(
              'option',
              {
                key: 'option_' + option.value + '_' + Math.random(),
                value: valueKey ? option[valueKey] : option.value,
                disabled: option.disabled === true },
              labelKey ? option[labelKey] : option.label
            );
          })
        ),
        React.createElement(
          _.Relative,
          null,
          React.createElement(
            SelectFake,
            { open: open },
            React.createElement(
              Options,
              null,
              options.map(function (opt) {
                return React.createElement(
                  Option,
                  {
                    key: 'opt_fake_' + opt.value + '_' + Math.random(),
                    onClick: opt.disabled ? null : _this2.handleSelect.bind(_this2, opt),
                    style: opt.style,
                    disabled: opt.disabled ? 'true' : 'false' },
                  labelKey ? opt[labelKey] : opt.label
                );
              })
            )
          )
        )
      );
    }
  }]);

  return SelectFilter;
}(React.Component);

var Select = _styledComponents2.default.div(_templateObject);

var SelectCore = (0, _styledComponents2.default)(_.Select)(_templateObject2);

var SelectFake = _styledComponents2.default.div(_templateObject3, function (props) {
  return props.open ? '100' : '-1';
}, function (props) {
  return props.open ? '1' : '0';
}, function (props) {
  return props.open ? 'all' : 'none';
});

var Options = _styledComponents2.default.ul(_templateObject4);

var Option = _styledComponents2.default.li(_templateObject5, function (props) {
  return (0, _util.color)(props)('shadow');
}, function (props) {
  return (0, _util.color)(props)('blue');
}, function (props) {
  return props.disabled === 'true' ? 'none' : 'auto';
}, function (props) {
  return props.disabled === 'true' ? _theme.colors.silverChalice : 'white';
});

exports.default = (0, _recompose.compose)(_withToggle2.default, (0, _recompose.withProps)(function (_ref) {
  var setOpen = _ref.setOpen;
  return {
    onClickOutside: function onClickOutside() {
      setOpen(false);
    }
  };
}), _withClickOutside2.default)(SelectFilter);