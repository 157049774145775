'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  height: ', ';\n'], ['\n  height: ', ';\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  margin-bottom: ', ';\n'], ['\n  margin-bottom: ', ';\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('../../');

var _Spinner = require('./Spinner');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var ActivityIndicator = function ActivityIndicator(_ref) {
  var _ref$withLoadingText = _ref.withLoadingText,
      withLoadingText = _ref$withLoadingText === undefined ? true : _ref$withLoadingText,
      loadingText = _ref.loadingText,
      loadingTextBottomMargin = _ref.loadingTextBottomMargin,
      color = _ref.color,
      height = _ref.height,
      backgroundColor = _ref.backgroundColor;

  return React.createElement(
    StyledWrapperFlex,
    {
      height: height,
      width: '100%',
      bg: backgroundColor || 'none',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center' },
    withLoadingText && React.createElement(
      StyledLoadingText,
      {
        fontSize: '1rem',
        align: 'center',
        marginbottom: loadingTextBottomMargin },
      loadingText || 'Loading'
    ),
    React.createElement(_Spinner.Spinner, { color: color })
  );
};

exports.default = ActivityIndicator;


var StyledWrapperFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject, function (props) {
  return props.height || '50vh';
});

var StyledLoadingText = (0, _styledComponents2.default)(_.Text)(_templateObject2, function (props) {
  return props.marginbottom ? props.marginbottom : '1rem';
});