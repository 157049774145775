'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  display: flex;\n'], ['\n  display: flex;\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  cursor: pointer;\n  border-radius: 4px;\n  border: 1px solid ', ';\n  right: 0;\n  &:after,\n  &:before {\n    bottom: 100%;\n    right: 4px;\n    border: 1px solid transparent;\n    content: \' \';\n    height: 0;\n    width: 0;\n    position: absolute;\n    pointer-events: none;\n  }\n\n  &:after {\n    border-color: rgba(255, 255, 255, 0);\n    border-bottom-color: #ffffff;\n    border-width: 6px;\n    margin-left: -6px;\n  }\n\n  &:before {\n    border-color: rgba(', ', 0);\n    border-bottom-color: ', ';\n    border-width: 7px;\n    margin-left: -7px;\n  }\n'], ['\n  cursor: pointer;\n  border-radius: 4px;\n  border: 1px solid ', ';\n  right: 0;\n  &:after,\n  &:before {\n    bottom: 100%;\n    right: 4px;\n    border: 1px solid transparent;\n    content: \' \';\n    height: 0;\n    width: 0;\n    position: absolute;\n    pointer-events: none;\n  }\n\n  &:after {\n    border-color: rgba(255, 255, 255, 0);\n    border-bottom-color: #ffffff;\n    border-width: 6px;\n    margin-left: -6px;\n  }\n\n  &:before {\n    border-color: rgba(', ', 0);\n    border-bottom-color: ', ';\n    border-width: 7px;\n    margin-left: -7px;\n  }\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ = require('..');

var _unstable = require('../unstable');

var _rgIcons = require('rg-icons');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _Avatar = require('./Avatar');

var _Avatar2 = _interopRequireDefault(_Avatar);

var _ArrowToggle = require('./ArrowToggle');

var _ArrowToggle2 = _interopRequireDefault(_ArrowToggle);

var _util = require('../util');

var _ramda = require('ramda');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SidebarHeader = function (_React$Component) {
  _inherits(SidebarHeader, _React$Component);

  function SidebarHeader() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, SidebarHeader);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = SidebarHeader.__proto__ || Object.getPrototypeOf(SidebarHeader)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      open: false
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(SidebarHeader, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          user = _props.user,
          onPopClick = _props.onPopClick,
          bg = _props.bg;
      var open = this.state.open;

      var commonProps = (0, _ramda.omit)(['user', 'onPopClick'], this.props);

      return React.createElement(
        _.Relative,
        null,
        React.createElement(
          _.Flex,
          _extends({
            flexDirection: 'column',
            bg: bg ? bg : '#273753',
            px: 3
          }, commonProps),
          React.createElement(
            _.Flex,
            { flexDirection: 'column', justify: 'center', pt: 3 },
            children
          ),
          React.createElement(
            _.Flex,
            { alignItems: 'center', justifyContent: 'space-between', py: 3 },
            React.createElement(
              _.Flex,
              { alignItems: 'center' },
              React.createElement(_Avatar2.default, { image: user.picture }),
              React.createElement(
                _.Flex,
                { flexDirection: 'column', ml: 3 },
                React.createElement(_.Truncate, {
                  children: user.name,
                  fontSize: 2,
                  bold: true,
                  mb: 1,
                  w: '80px'
                }),
                React.createElement(_.Small, { children: user.role, color: 'alabaster' })
              )
            ),
            React.createElement(
              _.Relative,
              { z: '1' },
              React.createElement(
                _unstable.Dropdown,
                null,
                React.createElement(_ArrowToggle2.default, {
                  open: open,
                  borderclosecolor: '#979797',
                  borderopencolor: '#80c50d',
                  iconclosecolor: 'white',
                  iconopencolor: '#80c50d'
                }),
                React.createElement(
                  StyledPopover,
                  { open: open },
                  React.createElement(
                    _.Flex,
                    { flexDirection: 'column' },
                    React.createElement(
                      _.Flex,
                      {
                        alignItems: 'center',
                        onClick: onPopClick,
                        p: 2,
                        w: '80px' },
                      React.createElement(_rgIcons.Icon, { name: 'logout', color: '#002a54' }),
                      React.createElement(
                        _.Text,
                        { color: 'primaryText', ml: 1, bold: true, is: 'span' },
                        'Log Out'
                      )
                    )
                  )
                )
              )
            )
          ),
          React.createElement(
            StyledAbsolute,
            { left: '0', bottom: '0' },
            React.createElement(
              'svg',
              { width: '248px', height: '38px', viewBox: '0 0 248 38' },
              React.createElement('defs', null),
              React.createElement(
                'g',
                {
                  stroke: 'none',
                  strokeWidth: '1',
                  fill: 'none',
                  fillRule: 'evenodd',
                  opacity: '0.512624547' },
                React.createElement(
                  'g',
                  { transform: 'translate(0.000000, -158.000000)', fill: '#354767' },
                  React.createElement('path', {
                    d: 'M-9.06774655e-14,196.673487 L247.824966,196.673487 C247.824962,185.796028 247.82496,177.319401 247.82496,171.243608 C224.938933,162.876107 202.375585,158.692357 180.134915,158.692357 C112.48538,158.692357 75.0292365,184.828718 21.1352365,184.828718 C13.2498081,184.828718 6.0217931,184.356181 -0.0338256366,183.596559 C-0.211576671,183.574262 -0.200301458,187.933238 -9.06774655e-14,196.673487 Z',
                    id: 'small-wave'
                  })
                )
              )
            )
          )
        )
      );
    }
  }]);

  return SidebarHeader;
}(React.Component);

var StyledAbsolute = (0, _styledComponents2.default)(_.Absolute)(_templateObject);

var StyledPopover = (0, _styledComponents2.default)(_unstable.Popover)(_templateObject2, function (props) {
  return (0, _util.color)(props)('blue');
}, function (props) {
  return (0, _util.color)(props)('blue');
}, function (props) {
  return (0, _util.color)(props)('blue');
});

exports.default = SidebarHeader;