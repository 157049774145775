//@flow

import {theme as themeGlobal} from 'uikit';

export const theme = {
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64, 72, 96],
  sideBar: {
    width: '248px',
  },
  main: {
    marginLeft: '248px',
    backgroundColor: '#efeff5',
    padding: `1.5rem 1.4375rem 1.5rem 1.3125rem`,
    paddingTop: `1.5rem`,
    paddingRight: `2.5625rem`,
    paddignBottom: `1.5rem`,
    paddingLeft: `1.3125rem`,
  },
  colors: {
    ...themeGlobal.colors,

    // dark blue
    matisse: '#345678',
    matisse80: 'rgba(52, 86, 119, .8)',
    matisseDark: '#2e4d6a',
    briliantCyanBlue: '#609ddb',
    cornflowerBlue: '#5b7ab4',
    cornflowerBlue2: '#636363',
    midnightBlue: '#354767',
    cloudyBlue: '#273753',
    cloudyBlueDarker: '#1d2c46',
    eastBay: '#425981',
    steelBlue: '#5B7AB4',

    // light blue
    prussianBlue: '#002a54',
    saltedEdd: 'rgba(91, 122, 180, 0.1)',
    jordyBlue: '#91b4f6',
    aliceBlue: '#F1F8FF',
    sail: '#B5D7F9',

    // white & grey
    white: '#fff',
    whiteSmoke: '#fbfbfb',
    lightGrey: '#d7d7d7',
    lightGrey50: 'rgba(225, 225, 225, 0.5)',
    greySmoke: '#f0f3f8',
    darkGrey: '#3e3e3e',
    grey20: 'rgba(151, 151, 151, 0.2)',
    grey50: 'rgba(151, 151, 151, 0.5)',
    dustyGray: '#979797',
    dustyGray30: 'rgba(151, 151, 151, 0.3)',
    catskillWhite: '#f1f4f9',
    catSkillWhite2: '#E1E8F3',
    alto: '#dbdbdb',
    mercury: '#e1e1e1',
    mineShaft: '#333333',
    mineShaft50: 'rgba(51,51,51,0.5)',
    codGray: '#161616',
    rollingStone: '#747A81',
    milkGrey: '#AFAFAF',
    softGrey: '#9F9F9F',
    tonedGrey: '#E0E0E0',
    concrete: '#f2f2f2',

    // black
    black: '#000',
    black50: 'rgba(0, 0, 0, .5)',
    black08: 'rgba(0, 0, 0, 0.08)',

    // red
    redChili: '#ff4351',
    red: '#FF0A0A',
    pomegranate: '#F5392A',
    softRed: '#FBBCC5',
    jaffa: '#F2994A',

    // green
    slimeGreen: '#80c50d',
    pistachio: '#c8f47e',
    atlantis: '#80c128',
    emerald: '#3ACA60',
    softGreen: '#D9E6CC',

    // misc
    abbey: '#444447',
    outerSpace: 'rgba(176, 176, 176, 0.5)',
    atoll: 'rgba(7, 96, 115, 0.07)',
    martinique: 'rgba(50, 50, 93, 0.1)',

    //yellow
    yellow: '#ffff00',
    saffron: '#F5AF2A',
  },
};

export const colors = theme.colors;

export default theme;
