'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _templateObject = _taggedTemplateLiteral(['\n  cursor: pointer;\n  height: 24px;\n  width: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n'], ['\n  cursor: pointer;\n  height: 24px;\n  width: 24px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _rgIcons = require('rg-icons');

var _ = require('..');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var ArrowToggle = function ArrowToggle(props) {
  var open = props.open,
      borderopencolor = props.borderopencolor,
      borderclosecolor = props.borderclosecolor,
      iconopencolor = props.iconopencolor,
      iconclosecolor = props.iconclosecolor;

  return React.createElement(
    StyledBorder,
    _extends({
      radius: '4px'
    }, props, {
      color: open ? borderopencolor : borderclosecolor }),
    React.createElement(_rgIcons.Icon, {
      name: 'chevronDown',
      size: '10px',
      color: open ? iconopencolor : iconclosecolor
    })
  );
};

var StyledBorder = (0, _styledComponents2.default)(_.Border)(_templateObject);

exports.default = ArrowToggle;