'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  transform: rotate(', ');\n'], ['\n  transform: rotate(', ');\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  & > a {\n    cursor: pointer;\n    display: flex;\n    position: relative;\n    align-items: center;\n    border-width: 0;\n    border-color: ', ';\n    border-style: solid;\n    border-top-width: 1px;\n  }\n\n  & > a > span {\n    margin-left: 10px;\n  }\n\n  & > .selected {\n    background-image: ', ';\n  }\n\n  & > .selected span {\n    font-weight: bold;\n  }\n\n  & > .selected::before {\n    content: \'\';\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 4px;\n    height: 100%;\n    background-color: #80c50d;\n    display: inline-block;\n  }\n'], ['\n  & > a {\n    cursor: pointer;\n    display: flex;\n    position: relative;\n    align-items: center;\n    border-width: 0;\n    border-color: ', ';\n    border-style: solid;\n    border-top-width: 1px;\n  }\n\n  & > a > span {\n    margin-left: 10px;\n  }\n\n  & > .selected {\n    background-image: ', ';\n  }\n\n  & > .selected span {\n    font-weight: bold;\n  }\n\n  & > .selected::before {\n    content: \'\';\n    position: absolute;\n    left: 0;\n    top: 0;\n    width: 4px;\n    height: 100%;\n    background-color: #80c50d;\n    display: inline-block;\n  }\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ = require('..');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _2 = require('./');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var SidebarMenuParent = function (_React$Component) {
  _inherits(SidebarMenuParent, _React$Component);

  function SidebarMenuParent() {
    _classCallCheck(this, SidebarMenuParent);

    return _possibleConstructorReturn(this, (SidebarMenuParent.__proto__ || Object.getPrototypeOf(SidebarMenuParent)).apply(this, arguments));
  }

  _createClass(SidebarMenuParent, [{
    key: 'render',

    // if rerendering props spread need to destruct?
    value: function render() {
      var _props = this.props,
          children = _props.children,
          childprop = _props.childprop,
          onClick = _props.onClick,
          open = _props.open,
          haschildren = _props.haschildren,
          productname = _props.productname,
          isActive = _props.isActive;


      var onArrowClick = function onArrowClick(e) {
        e.stopPropagation();
        e.preventDefault();
        onClick();
      };

      var arrow = React.createElement(
        StyledArrowToggle,
        {
          right: '10px',
          open: open,
          key: 'arrow-toggle',
          onClick: onArrowClick },
        React.createElement(_2.ArrowToggle, {
          open: open,
          borderclosecolor: '#979797',
          borderopencolor: '#979797',
          iconclosecolor: 'white',
          iconopencolor: 'white'
        })
      );

      var mergedChild = Array.isArray(childprop) && childprop.concat(arrow) || [childprop, arrow];

      var childrenWithProps = React.cloneElement(children, {
        p: 3,
        fontSize: 1,
        color: 'white',
        w: 1,
        className: isActive ? 'selected' : '',
        children: haschildren === 1 ? mergedChild : childprop
      });

      var bgImage = '';
      var borderColor = '';
      if (productname && productname === 'rg') {
        bgImage = 'none';
        borderColor = 'rgba(255,255, 255, 0.2)';
      } else {
        bgImage = 'linear-gradient(to left, #344667, #2e4164)';
        borderColor = '#344667';
      }
      return React.createElement(
        StyledFlex,
        {
          onClick: onClick,
          open: open,
          bgImage: bgImage,
          borderColor: borderColor },
        childrenWithProps
      );
    }
  }]);

  return SidebarMenuParent;
}(React.Component);

var StyledArrowToggle = (0, _styledComponents2.default)(_.Absolute)(_templateObject, function (props) {
  return props.open ? '180deg' : '0deg';
});

var StyledFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject2, function (props) {
  return props.borderColor;
}, function (props) {
  return props.bgImage;
});

exports.default = SidebarMenuParent;