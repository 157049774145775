'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  border-width: 0;\n  border-top-width: 1px;\n  border-style: solid;\n  padding: 1rem 2rem;\n  margin-top: -1px;\n  border-color: ', '\n  background-color: ', '\n'], ['\n  border-width: 0;\n  border-top-width: 1px;\n  border-style: solid;\n  padding: 1rem 2rem;\n  margin-top: -1px;\n  border-color: ', '\n  background-color: ', '\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var SidebarMenuChild = function SidebarMenuChild(_ref) {
  var children = _ref.children,
      productname = _ref.productname;

  var childrenWithProps = React.cloneElement(children, {
    color: 'white',
    fontSize: 1,
    style: {
      display: 'flex',
      alignItems: 'baseline'
    }
  });

  var bgColor = productname === 'ruker' ? '#273753' : '#ffffff00';
  var borderColor = productname === 'ruker' ? '#1d2c46' : '#ffffff4d';

  return React.createElement(
    BorderBox,
    { borderColor: borderColor, bgColor: bgColor },
    childrenWithProps
  );
};

var BorderBox = _styledComponents2.default.div(_templateObject, function (props) {
  return props.borderColor;
}, function (props) {
  return props.bgColor;
});

exports.default = SidebarMenuChild;