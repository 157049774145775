'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  width: 100%;\n  height: 0.5em;\n  border-radius: 6.5px;\n  background-color: ', ';\n'], ['\n  width: 100%;\n  height: 0.5em;\n  border-radius: 6.5px;\n  background-color: ', ';\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  display: block;\n  width: ', ';\n  height: 100%;\n  border-radius: 1em;\n  background-color: ', ';\n'], ['\n  display: block;\n  width: ', ';\n  height: 100%;\n  border-radius: 1em;\n  background-color: ', ';\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ = require('../');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var ProgressBar = function (_React$Component) {
  _inherits(ProgressBar, _React$Component);

  function ProgressBar() {
    _classCallCheck(this, ProgressBar);

    return _possibleConstructorReturn(this, (ProgressBar.__proto__ || Object.getPrototypeOf(ProgressBar)).apply(this, arguments));
  }

  _createClass(ProgressBar, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          progressAmount = _props.progressAmount,
          backgroundColor = _props.backgroundColor,
          withLabel = _props.withLabel,
          width = _props.width;

      return React.createElement(
        _.Box,
        { w: width },
        React.createElement(
          BarContainer,
          null,
          React.createElement(BarProgress, {
            backgroundColor: backgroundColor,
            progressAmount: progressAmount
          })
        ),
        withLabel && React.createElement(
          _.Text,
          { ml: '1rem' },
          progressAmount,
          '%'
        )
      );
    }
  }]);

  return ProgressBar;
}(React.Component);

ProgressBar.defaultProps = {
  withLabel: false,
  width: 1
};


var BarContainer = _styledComponents2.default.div(_templateObject, function (props) {
  return props.theme.colors.lightGrey;
});

var BarProgress = _styledComponents2.default.span(_templateObject2, function (props) {
  var progressAmount = props.progressAmount;

  if (progressAmount) {
    return progressAmount + '%';
  } else {
    return '0';
  }
}, function (props) {
  var progressAmount = props.progressAmount,
      backgroundColor = props.backgroundColor,
      theme = props.theme;

  if (!!backgroundColor) {
    return '' + backgroundColor;
  } else {
    if (progressAmount > 50) {
      return '' + theme.colors.slimeGreen;
    } else {
      return '' + theme.colors.redChili;
    }
  }
});

exports.default = ProgressBar;