// @flow
const companiesData = [
  {
    companySerial: 'COM-001',
    companyName: 'Pertamina Persero',
    departments: [
      {
        departmentSerial: 'DPRTMNT-AFS67OKO7CD',
        departmentName: 'Finances',
      },
      {
        departmentSerial: 'DPRTMNT-JJF26WXT1HZ',
        departmentName: 'Tech Support',
      },
      {
        departmentSerial: 'DPRTMNT-LIF99QYN7HO',
        departmentName: 'Customer Service',
      },
      {
        departmentSerial: 'DPRTMNT-MNB05NXP6DC',
        departmentName: 'Tech Support',
      },
      {
        departmentSerial: 'DPRTMNT-NKK17BHH3XW',
        departmentName: 'Media Relations',
      },
    ],
  },
  {
    companySerial: 'COM-002',
    companyName: 'Anak Perusahaan 1',
    departments: [
      {
        departmentSerial: 'DPRTMNT-EWF06NQQ5EO',
        departmentName: 'Legal Department',
      },
      {
        departmentSerial: 'DPRTMNT-IDX87HLU6UQ',
        departmentName: 'Tech Support',
      },
      {
        departmentSerial: 'DPRTMNT-PEE28JEF5WK',
        departmentName: 'Legal Department',
      },
      {
        departmentSerial: 'DPRTMNT-QIZ96LYS0DP',
        departmentName: 'Customer Relations',
      },
      {
        departmentSerial: 'DPRTMNT-ZRI28QKF7AS',
        departmentName: 'Finances',
      },
    ],
  },
  {
    companySerial: 'COM-003',
    companyName: 'Anak Perusahaan 2',
    departments: [
      {
        departmentSerial: 'DPRTMNT-RMO45QFG2JT',
        departmentName: 'Sales and Marketing',
      },
      {
        departmentSerial: 'DPRTMNT-UVA15KIF6DL',
        departmentName: 'Research and Development',
      },
      {
        departmentSerial: 'DPRTMNT-WJA22ZEG7EP',
        departmentName: 'Asset Management',
      },
      {
        departmentSerial: 'DPRTMNT-XHD06SMI5UN',
        departmentName: 'Finances',
      },
      {
        departmentSerial: 'DPRTMNT-ZPQ79UYS9LJ',
        departmentName: 'Payroll',
      },
    ],
  },
];

const tagsData = [
  {
    tagSerial: 'tag1',
    tagName: 'Business Management',
  },
  {
    tagSerial: 'tag2',
    tagName: 'Reservoir & Production Engineering',
  },
  {
    tagSerial: 'tag3',
    tagName: 'Surface Facilities',
  },
  {
    tagSerial: 'tag4',
    tagName: 'Drilling',
  },
  {
    tagSerial: 'tag5',
    tagName: 'Refinery',
  },
  {
    tagSerial: 'tag6',
    tagName: 'Engineering',
  },
  {
    tagSerial: 'tag7',
    tagName: 'Marketing and Sales',
  },
  {
    tagSerial: 'tag8',
    tagName: 'Supply Chain Management',
  },
];

const courseSummariesData = [
  {
    courseSerial: 'course1',
    courseName: 'Junior Health, Safety, Security, and Environment (JHSEE)',
    numberOfParticipants: 3200,
    numberOfCompleted: 960,
    numberOfInProgress: 960,
    numberOfNotStarted: 1280,
    avgPreTest: 64,
    avgPostTest: 78,
  },
  {
    courseSerial: 'course2',
    courseName: 'Value Based Development Program',
    numberOfParticipants: 3200,
    numberOfCompleted: 2880,
    numberOfInProgress: 320,
    numberOfNotStarted: 0,
    avgPreTest: 74,
    avgPostTest: 88,
  },
  {
    courseSerial: 'course3',
    courseName: 'Junior Health, Safety, Security, and Environment (JHSEE) - 2',
    numberOfParticipants: 3200,
    numberOfCompleted: 0,
    numberOfInProgress: 3000,
    numberOfNotStarted: 200,
    avgPreTest: 80,
    avgPostTest: 0,
  },
  {
    courseSerial: 'course4',
    courseName: 'To MR or Not to MR',
    numberOfParticipants: 3200,
    numberOfCompleted: 2000,
    numberOfInProgress: 300,
    numberOfNotStarted: 900,
    avgPreTest: 50,
    avgPostTest: 93,
  },
];

const courseTopicsData = [
  {
    topicSerial: 'topic11',
    topicName: 'Safety',
    topicIconURL: 'https://placeimg.com/48/48/tech',
    numberOfParticipants: 3200,
    numberOfCompleted: 600,
    numberOfInProgress: 1000,
    numberOfNotStarted: 1600,
  },
  {
    topicSerial: 'topic12',
    topicName: 'Security',
    topicIconURL: 'https://placeimg.com/48/48/tech',
    numberOfParticipants: 3200,
    numberOfCompleted: 600,
    numberOfInProgress: 1000,
    numberOfNotStarted: 1600,
  },
  {
    topicSerial: 'topic13',
    topicName: 'Occupational Health',
    topicIconURL: 'https://placeimg.com/48/48/tech',
    numberOfParticipants: 3200,
    numberOfCompleted: 600,
    numberOfInProgress: 1000,
    numberOfNotStarted: 1600,
  },
  {
    topicSerial: 'topic14',
    topicName: 'Environment',
    topicIconURL: 'https://placeimg.com/48/48/tech',
    numberOfParticipants: 3200,
    numberOfCompleted: 600,
    numberOfInProgress: 1000,
    numberOfNotStarted: 1600,
  },
  {
    topicSerial: 'topic15',
    topicName: 'Medical',
    topicIconURL: 'https://placeimg.com/48/48/tech',
    numberOfParticipants: 3200,
    numberOfCompleted: 600,
    numberOfInProgress: 1000,
    numberOfNotStarted: 1600,
  },
];

const courseUsersData = ({page}: {page: number}) => {
  return {
    currentPage: parseInt(page),
    totalItems: 100,
    totalPage: 10,
    items: [
      {
        userSerial: 'user1',
        employeeId: 'employee1',
        name: 'Nama Employee Satu',
        preTest: 87,
        postTest: 83,
        timeSpent: 30,
        status: 'Completed',
      },
      {
        userSerial: 'user2',
        employeeId: 'employee2',
        name: 'Nama Employee Dua',
        preTest: 87,
        postTest: null,
        timeSpent: 30,
        status: 'In Progress',
      },
      {
        userSerial: 'user3',
        employeeId: 'employee3',
        name: 'Nama Employee Tiga',
        preTest: 87,
        postTest: null,
        timeSpent: 30,
        status: 'In Progress',
      },
      {
        userSerial: 'user4',
        employeeId: 'employee4',
        name: 'Nama Employee Empat',
        preTest: 87,
        postTest: 83,
        timeSpent: 30,
        status: 'Completed',
      },
      {
        userSerial: 'user5',
        employeeId: 'employee5',
        name: 'Nama Employee Lima',
        preTest: 87,
        postTest: null,
        timeSpent: 30,
        status: 'In Progress',
      },
      {
        userSerial: 'user6',
        employeeId: 'employee6',
        name: 'Nama Employee Enam',
        preTest: null,
        postTest: null,
        timeSpent: null,
        status: 'Not Started',
      },
      {
        userSerial: 'user7',
        employeeId: 'employee7',
        name: 'Nama Employee Tujuh',
        preTest: null,
        postTest: null,
        timeSpent: null,
        status: 'Not Started',
      },
      {
        userSerial: 'user8',
        employeeId: 'employee8',
        name: 'Nama Employee Delapan',
        preTest: null,
        postTest: null,
        timeSpent: null,
        status: 'Not Started',
      },
      {
        userSerial: 'user9',
        employeeId: 'employee9',
        name: 'Nama Employee Sembilan',
        preTest: 87,
        postTest: 83,
        timeSpent: 30,
        status: 'Completed',
      },
      {
        userSerial: 'user10',
        employeeId: 'employee10',
        name: 'Nama Employee Sepuluh',
        preTest: 87,
        postTest: null,
        timeSpent: 30,
        status: 'In Progress',
      },
    ],
  };
};

const progressDataCourse = [
  {
    date: '2017-07-01',
    numberOfCompleted: 0,
  },
  {
    date: '2017-08-01',
    numberOfCompleted: 0,
  },
  {
    date: '2017-09-01',
    numberOfCompleted: 20,
  },
  {
    date: '2017-10-01',
    numberOfCompleted: 30,
  },
  {
    date: '2017-11-01',
    numberOfCompleted: 50,
  },
  {
    date: '2017-12-01',
    numberOfCompleted: 70,
  },
  {
    date: '2018-01-01',
    numberOfCompleted: 100,
  },
  {
    date: '2018-02-01',
    numberOfCompleted: 200,
  },
  {
    date: '2018-03-01',
    numberOfCompleted: 400,
  },
  {
    date: '2018-04-01',
    numberOfCompleted: 800,
  },
  {
    date: '2018-05-01',
    numberOfCompleted: 1600,
  },
  {
    date: '2018-06-01',
    numberOfCompleted: 3200,
  },
];

const progressDataUsers = {
  completionData: [
    {
      date: '2017-07-01',
      numberOfCompleted: 0,
    },
    {
      date: '2017-08-01',
      numberOfCompleted: 0,
    },
    {
      date: '2017-09-01',
      numberOfCompleted: 20,
    },
    {
      date: '2017-10-01',
      numberOfCompleted: 30,
    },
    {
      date: '2017-11-01',
      numberOfCompleted: 50,
    },
    {
      date: '2017-12-01',
      numberOfCompleted: 70,
    },
    {
      date: '2018-01-01',
      numberOfCompleted: 100,
    },
    {
      date: '2018-02-01',
      numberOfCompleted: 200,
    },
    {
      date: '2018-03-01',
      numberOfCompleted: 400,
    },
    {
      date: '2018-04-01',
      numberOfCompleted: 800,
    },
    {
      date: '2018-05-01',
      numberOfCompleted: 1600,
    },
    {
      date: '2018-06-01',
      numberOfCompleted: 3200,
    },
  ],
  totalUsers: 10000,
};

module.exports = {
  '/ruangkerja/analytic/learning-plan/summaries/LRPN-12EU3VC8/courses': {
    status: 'success',
    data: {
      currentPage: 1,
      totalItems: 30,
      totalPage: 10,
      items: [
        {
          courseSerial: 'COURSE-00F714CD',
          courseType: 'ONLINE',
          courseName: 'Course PDF',
          numberOfParticipants: 3,
          numberOfBatch: 0,
          numberOfCompleted: 0,
          numberOfInProgress: 1,
          numberOfNotStarted: 0,
          numberOfExpired: 2,
          numberOfFailed: 0,
          avgPreTest: 0,
          avgPostTest: 0,
          deadline: "2021-09-22T03:10:00Z"
        },
        {
          courseSerial: 'COURSE-017MV5QZ',
          courseType: 'ONLINE',
          courseName: 'course post test form feedback',
          numberOfParticipants: 1,
          numberOfBatch: 0,
          numberOfCompleted: 0,
          numberOfInProgress: 0,
          numberOfNotStarted: 1,
          numberOfExpired: 0,
          numberOfFailed: 0,
          avgPreTest: 0,
          avgPostTest: 0,
          deadline: null,
        },
        {
          courseSerial: 'COURSE-09V2PZVG',
          courseType: 'ONLINE',
          courseName: 'Test Time Limit',
          numberOfParticipants: 2,
          numberOfBatch: 0,
          numberOfCompleted: 0,
          numberOfInProgress: 0,
          numberOfNotStarted: 0,
          numberOfExpired: 2,
          numberOfFailed: 0,
          avgPreTest: 0,
          avgPostTest: 0,
        },
      ],
    },
  },
  'ruangkerja/dashboard/test-summary': {
    status: 'success',
    data: [
      {
        type: 'pre-test',
        numberOfParticipants: 5,
        numberOfCompleted: 2,
        numberOfInProgress: 1,
        numberOfNotStarted: 2,
      },
      {
        type: 'post-test',
        numberOfParticipants: 5,
        numberOfCompleted: 2,
        numberOfInProgress: 1,
        numberOfNotStarted: 2,
      },
    ],
  },
  'ruangkerja/cms/course/course-serial/test-analysis': {
    status: 'success',
    data: {
      currentPage: 1,
      totalItems: 3,
      totalPage: 1,
      items: [
        {
          questionSerial: 'QS1',
          question: 'apakah?',
          usersCorrectAnswer: 12,
          usersWrongAnswer: 4,
          percentage: 60,
        },
        {
          questionSerial: 'QS2',
          question: 'apakah?',
          usersCorrectAnswer: 4,
          usersWrongAnswer: 6,
          percentage: 40,
        },
        {
          questionSerial: 'QS3',
          question: 'apakah?',
          usersCorrectAnswer: 7,
          usersWrongAnswer: 2,
          percentage: 82,
        },
      ],
    },
  },
  'ruangkerja/cms/course/course-serial/user-test-result': {
    status: 'success',
    data: {
      currentPage: 1,
      totalItems: 3,
      totalPage: 1,
      items: [
        {
          employeeID: 'EMP1',
          name: 'Employee 1',
          correctAnswer: 12,
          wrongAnswer: 4,
          timeSpent: '00:20:23',
          currentAttempts: 1,
          maxAttempts: 3,
          testStatus: 'Completed',
        },
        {
          employeeID: 'EMP2',
          name: 'Employee 2',
          correctAnswer: 4,
          wrongAnswer: 8,
          timeSpent: '00:40:08',
          currentAttempts: 3,
          maxAttempts: 3,
          testStatus: 'Failed',
        },
        {
          employeeID: 'EMP3',
          name: 'Employee 3',
          correctAnswer: 3,
          wrongAnswer: 4,
          timeSpent: '00:05:12',
          currentAttempts: 2,
          maxAttempts: 3,
          testStatus: 'Expired',
        },
      ],
    },
  },
  'ruangkerja/cms/course/course-serial/questions-list': {
    status: 'success',
    data: {
      questions: [
        {
          contents:
            '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_tgnh0g_2078.jpeg"/></figure>\n<div>Test aja ini mahh</div>\n<div><br></div>\n<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_xrvswp_7896.jpeg"/></figure>\n<div>test</div>',
          answers: [
            {
              answer:
                '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>b</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>c</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
          ],
          correctAnswers: [0, 2],
          serial: 'QU-ZQBIP3AT',
        },
        {
          contents: 'apakah?',
          answers: [
            {
              answer:
                '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>b</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>c</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
          ],
          correctAnswers: [2],
          serial: 'QU-ZQBIP3Q2',
        },
        {
          contents:
            '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_tgnh0g_2078.jpeg"/></figure>\n<div>Test aja ini mahh</div>\n<div><br></div>\n<div><br></div>\n<div>test</div>',
          answers: [
            {
              answer:
                '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>b</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>c</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
          ],
          correctAnswers: [1],
          serial: 'QU-ZQBIP35T',
        },
        {
          contents:
            '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_tgnh0g_2078.jpeg"/></figure>\n<div>Test aja ini mahh</div>\n<div><br></div>\n<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_xrvswp_7896.jpeg"/></figure>\n<div>test</div>',
          answers: [
            {
              answer:
                '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>b</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>c</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
          ],
          correctAnswers: [0],
          serial: 'QU-ZQBIP3AT',
        },
        {
          contents: 'apakah?',
          answers: [
            {
              answer:
                '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>b</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>c</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
          ],
          correctAnswers: [2],
          serial: 'QU-ZQBIP3Q2',
        },
        {
          contents:
            '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_tgnh0g_2078.jpeg"/></figure>\n<div>Test aja ini mahh</div>\n<div><br></div>\n<div><br></div>\n<div>test</div>',
          answers: [
            {
              answer:
                '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>b</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>c</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
          ],
          correctAnswers: [1],
          serial: 'QU-ZQBIP35T',
        },
        {
          contents:
            '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_tgnh0g_2078.jpeg"/></figure>\n<div>Test aja ini mahh</div>\n<div><br></div>\n<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_xrvswp_7896.jpeg"/></figure>\n<div>test</div>',
          answers: [
            {
              answer:
                '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>b</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>c</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
          ],
          correctAnswers: [0],
          serial: 'QU-ZQBIP3AT',
        },
        {
          contents: 'apakah?',
          answers: [
            {
              answer:
                '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>b</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>c</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
          ],
          correctAnswers: [2],
          serial: 'QU-ZQBIP3Q2',
        },
        {
          contents:
            '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_tgnh0g_2078.jpeg"/></figure>\n<div>Test aja ini mahh</div>\n<div><br></div>\n<div><br></div>\n<div>test</div>',
          answers: [
            {
              answer:
                '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>b</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
            {
              answer: '<div>c</div>',
              percentageUser: 40,
              totalUsers: 120,
            },
          ],
          correctAnswers: [1],
          serial: 'QU-ZQBIP35T',
        },
      ],
    },
  },
  'ruangkerja/cms/course/course-serial/test-user-detail-list': {
    status: 'success',
    data: {
      questions: [
        {
          serial: 'QU-ZQBIP35T',
          contents:
            '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_tgnh0g_2078.jpeg"/></figure>\n<div>Test aja ini mahh</div>\n<div><br></div>\n<div><br></div>\n<div>test</div>',
          answers: [
            '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
            '<div>b</div>',
            '<div>c</div>',
          ],
          userAnswer: 0,
          correctAnswers: [0, 2],
        },
        {
          serial: 'QU-ZQBIP3Q2',
          contents: 'apakah?',
          answers: [
            '<div><br></div>\n<figure><img src="https://core-ruangguru.s3.amazonaws.com/assets/miscellaneous/image_utl6ag_114.jpeg"/></figure>\n<div>test</div>',
            '<div>b</div>',
            '<div>c</div>',
          ],
          userAnswer: 1,
          correctAnswers: [2],
        },
      ],
    },
  },
  'ruangkerja/dashboard/main/weekly-active-learner-chart': {
    status: 'success',
    data: [
      {
        label: 'nov w1',
        value: 8,
      },
      {
        label: 'nov w2',
        value: 22,
      },
      {
        label: 'nov w3',
        value: 15,
      },
      {
        label: 'nov w4',
        value: 7,
      },
      {
        label: 'des w1',
        value: 20,
      },
      {
        label: 'des w2',
        value: 18,
      },
      {
        label: 'des w3',
        value: 40,
      },
      {
        label: 'des w4',
        value: 10,
      },
      {
        label: 'jan w1',
        value: 60,
      },
      {
        label: 'jan w2',
        value: 5,
      },
    ],
  },

  'ruangkerja/dashboard/main/average-learning-time-spent-chart': {
    status: 'success',
    data: [
      {
        label: '1 jan',
        value: 10,
      },
      {
        label: '12 jan',
        value: 5,
      },
      {
        label: '3 jan',
        value: 2,
      },
      {
        label: '4 jan',
        value: 0,
      },
      {
        label: '5 jan',
        value: 3,
      },
      {
        label: '6 jan',
        value: 12,
      },

      {
        label: '7 jan',
        value: 10,
      },
      {
        label: '8 jan',
        value: 15,
      },
      {
        label: '9 jan',
        value: 12,
      },
      {
        label: '10 jan',
        value: 6,
      },
      {
        label: '11 jan',
        value: 23,
      },
      {
        label: '12 jan',
        value: 1,
      },
    ],
  },

  'ruangkerja/dashboard/main/lifetime-cumulative-learning-time-spent-chart': {
    status: 'success',
    data: [
      {
        label: '1 jan',
        value: 10,
      },
      {
        label: '12 jan',
        value: 25,
      },
      {
        label: '3 jan',
        value: 22,
      },
      {
        label: '4 jan',
        value: 20,
      },
      {
        label: '5 jan',
        value: 13,
      },
      {
        label: '6 jan',
        value: 2,
      },

      {
        label: '7 jan',
        value: 14,
      },
      {
        label: '8 jan',
        value: 5,
      },
      {
        label: '9 jan',
        value: 2,
      },
      {
        label: '10 jan',
        value: 6,
      },
      {
        label: '11 jan',
        value: 3,
      },
      {
        label: '12 jan',
        value: 12,
      },
    ],
  },

  // Note: disable when the api is available
  // 'ruangkerja/dashboard/chart/cumulativePreTestPostTestScore': {
  //   status: 'success',
  //   data: {
  //     cumulativePreTestPostTestScoreDatas: [
  //       {
  //         period: '21 Nov',
  //         avgPreTest: 10,
  //         avgPostTest: 8,
  //       },
  //       {
  //         period: '21 Dec',
  //         avgPreTest: 9,
  //         avgPostTest: 5,
  //       },
  //       {
  //         period: '21 Jan',
  //         avgPreTest: 12,
  //         avgPostTest: 6,
  //       },
  //     ],
  //   },
  // },

  // 'ruangkerja/dashboard/chart/cumulativePreTestPostTestScore/detail': {
  //   status: 'success',
  //   data: {
  //     currentPage: 1,
  //     totalItems: 20,
  //     totalPage: 2,
  //     cumulativePreTestPostTestScoreDatas: [
  //       {
  //         period: '21 Nov',
  //         totalCourse: 20,
  //         totalAssignedLearners: 10,
  //         avgPreTest: 10,
  //         avgPostTest: 8,
  //       },
  //       {
  //         period: '21 Dec',
  //         totalCourse: 20,
  //         totalAssignedLearners: 10,
  //         avgPreTest: 9,
  //         avgPostTest: 5,
  //       },
  //       {
  //         period: '21 Jan',
  //         totalCourse: 20,
  //         totalAssignedLearners: 10,
  //         avgPreTest: 12,
  //         avgPostTest: 6,
  //       },
  //     ],
  //   },
  // },

  // 'ruangkerja/viewer_context?token=undefined&refreshToken=undefined&clientId=ekh6PbuuO8swo2h5ajYJ&clientSecret=ekh6PbuuO8swo2h5ajYJ': {
  //   status: 'success',
  //   data: {
  //     deviceId: 'None',
  //     deviceName: 'None',
  //     userSerial: 'company1',
  //     token: 'companytoken',
  //     refreshToken: 'companyrefreshtoken',
  //     isLoggedIn: 'true',
  //   },
  // },
  //
  // 'ruangkerja/dashboard/filter/company': {
  //   status: 'success',
  //   data: companiesData,
  // },
  //
  // 'ruangkerja/dashboard/company/skill-set': {
  //   status: 'success',
  //   data: tagsData,
  // },
  //
  // 'ruangkerja/dashboard/course/summary': {
  //   status: 'success',
  //   data: courseSummariesData,
  // },
  //
  // 'ruangkerja/dashboard/course/course2/summary': {
  //   status: 'success',
  //   data: courseTopicsData,
  // },
  //
  // 'ruangkerja/dashboard/course/course2/user': (param: {page: number}) => {
  //   return {
  //     status: 'success',
  //     data: courseUsersData({page: param.page}),
  //   };
  // },
  //
  // 'ruangkerja/dashboard/department/summary': (param: {companySerials: any}) => {
  //   const {companySerials} = param;
  //   if (companySerials === 'company1') {
  //     return {
  //       status: 'success',
  //       data: [
  //         {
  //           departmentName: 'Pemasaran Korporat',
  //           departmentSerial: 'depart324',
  //           overralProgress: 40.5,
  //           numberOfCoursesAssigned: 500,
  //           numberOfCoursesCompleted: 150,
  //           numberOfCompletedBy: 20,
  //           numberOfAssignedBy: 100,
  //           avgPreTest: 90.5,
  //           avgPostTest: 95.0,
  //         },
  //         {
  //           departmentName: 'Hulu',
  //           departmentSerial: 'depart123',
  //           overralProgress: 70,
  //           numberOfCoursesAssigned: 500,
  //           numberOfCoursesCompleted: 150,
  //           numberOfCompletedBy: 20,
  //           numberOfAssignedBy: 100,
  //           avgPreTest: 75.5,
  //           avgPostTest: 80.0,
  //         },
  //         {
  //           departmentName: 'Pengolahan',
  //           departmentSerial: 'depart234',
  //           overralProgress: 89.8,
  //           numberOfCoursesAssigned: 500,
  //           numberOfCoursesCompleted: 150,
  //           numberOfCompletedBy: 20,
  //           numberOfAssignedBy: 100,
  //           avgPreTest: 45.5,
  //           avgPostTest: 77,
  //         },
  //       ],
  //     };
  //   } else if (companySerials === 'company2') {
  //     return {
  //       status: 'success',
  //       data: [
  //         {
  //           departmentName: 'Pemasaran Korporat',
  //           departmentSerial: 'depart324',
  //           overralProgress: 4,
  //           numberOfCoursesAssigned: 500,
  //           numberOfCoursesCompleted: 150,
  //           numberOfCompletedBy: 20,
  //           numberOfAssignedBy: 100,
  //           avgPreTest: 90.5,
  //           avgPostTest: 95.0,
  //         },
  //         {
  //           departmentName: 'Hulu',
  //           departmentSerial: 'depart123',
  //           overralProgress: 20,
  //           numberOfCoursesAssigned: 500,
  //           numberOfCoursesCompleted: 150,
  //           numberOfCompletedBy: 20,
  //           numberOfAssignedBy: 100,
  //           avgPreTest: 75.5,
  //           avgPostTest: 80.0,
  //         },
  //       ],
  //     };
  //   } else {
  //     return {
  //       status: 'success',
  //       data: [],
  //     };
  //   }
  // },
  //
  // 'ruangkerja/dashboard/department/depart123': (params: {
  //   departmentSerial: any,
  //   filterType: any,
  //   filterValue: any,
  //   filterOperation: any,
  // }) => {
  //   const {departmentSerial, filterType, filterValue, filterOperation} = params;
  //   if (
  //     departmentSerial === 'depart123' &&
  //     filterType === 'postTest' &&
  //     filterValue === 7 &&
  //     filterOperation === 'greaterThan'
  //   ) {
  //     return {
  //       status: 'success',
  //       data: {
  //         currentPage: 1,
  //         totalItems: 1,
  //         totalPage: 1,
  //         items: [
  //           {
  //             userSerial: 'user123',
  //             employeeId: 'employee123',
  //             name: 'Adhi',
  //             preTest: 6.0,
  //             postTest: 8.0,
  //             timeSpent: 3600,
  //             numberOfCoursesAssigned: 5,
  //             numberOfCoursesCompleted: 3,
  //             status: 'On Going',
  //           },
  //         ],
  //       },
  //     };
  //   } else if (
  //     departmentSerial === 'depart123' &&
  //     filterType === 'name' &&
  //     filterValue === 'Prasmono' &&
  //     filterOperation === 'equal'
  //   ) {
  //     return {
  //       status: 'success',
  //       data: {
  //         currentPage: 1,
  //         totalItems: 1,
  //         totalPage: 1,
  //         items: [
  //           {
  //             userSerial: 'user234',
  //             employeeId: 'employee234',
  //             name: 'Prasmono',
  //             preTest: 6.0,
  //             postTest: 6.5,
  //             timeSpent: 6000,
  //             numberOfCoursesAssigned: 5,
  //             numberOfCoursesCompleted: 2,
  //             status: 'Failed',
  //           },
  //         ],
  //       },
  //     };
  //   } else if (departmentSerial === 'depart123' && !filterType) {
  //     return {
  //       status: 'success',
  //       data: {
  //         currentPage: 1,
  //         totalItems: 3,
  //         totalPage: 1,
  //         items: [
  //           {
  //             userSerial: 'user123',
  //             employeeId: 'employee123',
  //             name: 'Adhi',
  //             preTest: 6.0,
  //             postTest: 8.0,
  //             timeSpent: 3600,
  //             numberOfCoursesAssigned: 5,
  //             numberOfCoursesCompleted: 3,
  //             status: 'On Going',
  //           },
  //           {
  //             userSerial: 'user234',
  //             employeeId: 'employee234',
  //             name: 'Prasmono',
  //             preTest: 6.0,
  //             postTest: 6.5,
  //             timeSpent: 6000,
  //             numberOfCoursesAssigned: 5,
  //             numberOfCoursesCompleted: 2,
  //             status: 'Failed',
  //           },
  //           {
  //             userSerial: 'user345',
  //             employeeId: 'employee345',
  //             name: 'Surya',
  //             preTest: 6.0,
  //             postTest: 8.5,
  //             timeSpent: 1600,
  //             numberOfCoursesAssigned: 5,
  //             numberOfCoursesCompleted: 5,
  //             status: 'Cleared',
  //           },
  //         ],
  //       },
  //     };
  //   } else {
  //     return {
  //       status: 'success',
  //       data: {
  //         currentPage: 1,
  //         totalItems: 0,
  //         totalPage: 0,
  //         items: [],
  //       },
  //     };
  //   }
  // },
  //
  // TODO: change params to not use any
  // 'ruangkerja/dashboard/user': (param: any) => {
  //   const {searchBy, query} = param;
  //   if (searchBy === 'name' && query === 'Prasmono') {
  //     return {
  //       status: 'success',
  //       data: {
  //         currentPage: 1,
  //         totalItems: 1,
  //         totalPage: 1,
  //         items: [
  //           {
  //             userSerial: 'user234',
  //             employeeId: 'employee234',
  //             name: 'Prasmono',
  //             preTest: 6.0,
  //             postTest: 6.5,
  //             timeSpent: 6000,
  //             status: 'Failed',
  //             overralProgress: 40,
  //             numberOfCoursesAssigned: 5,
  //             numberOfCoursesCompleted: 2,
  //           },
  //         ],
  //       },
  //     };
  //   } else if (searchBy === 'employeeID' && query === 'employee345') {
  //     return {
  //       status: 'success',
  //       data: {
  //         currentPage: 1,
  //         totalItems: 1,
  //         totalPage: 1,
  //         items: [
  //           {
  //             userSerial: 'user345',
  //             employeeId: 'employee345',
  //             name: 'Surya',
  //             preTest: 6.0,
  //             postTest: 8.5,
  //             timeSpent: 1600,
  //             status: 'Cleared',
  //             overralProgress: 100,
  //             numberOfCoursesAssigned: 5,
  //             numberOfCoursesCompleted: 5,
  //           },
  //         ],
  //       },
  //     };
  //   } else {
  //     return {
  //       status: 'success',
  //       data: {
  //         currentPage: 1,
  //         totalItems: 0,
  //         totalPage: 0,
  //         items: [],
  //       },
  //     };
  //   }
  // },
  //
  // 'ruangkerja/dashboard/user/user234/summary': (param: any) => {
  //   return {
  //     status: 'success',
  //     data: {
  //       userSerial: 'user234',
  //       employeeId: 'employee234',
  //       name: 'Prasmono',
  //       email: 'Prasmono@ruangguru.com',
  //       picture: 'pr',
  //       tags: [],
  //       departmentName: 'Hulu',
  //       overralProgress: 40,
  //       numberOfCoursesAssigned: 5,
  //       numberOfCoursesCompleted: 2,
  //       numberOfCoursesInProgress: 1,
  //       numberOfCoursesNotStarted: 1,
  //       preTest: 6,
  //       postTest: 6.5,
  //       timeSpent: 60000,
  //       status: 'Failed',
  //     },
  //   };
  // },
  //
  // 'ruangkerja/dashboard/user/user345/summary': (param: any) => {
  //   return {
  //     status: 'success',
  //     data: {
  //       userSerial: 'user345',
  //       employeeId: 'employee345',
  //       name: 'Surya',
  //       email: 'Surya@ruangguru.com',
  //       picture:
  //         'https://www.albawaba.com/sites/default/files/im/misc/orange.jpg',
  //       tags: ['Business Management', 'Pengolahan'],
  //       departmentName: 'Hulu',
  //       overralProgress: 100,
  //       numberOfCoursesAssigned: 5,
  //       numberOfCoursesCompleted: 0,
  //       numberOfCoursesInProgress: 0,
  //       numberOfCoursesNotStarted: 1,
  //       preTest: 6.0,
  //       postTest: 8.5,
  //       timeSpent: 1600,
  //       status: 'Cleared',
  //     },
  //   };
  // },
  //
  // 'ruangkerja/dashboard/user/user234/courses': (param: any) => {
  //   const {status} = param;
  //   if (status === 'completed') {
  //     return {
  //       status: 'success',
  //       data: [
  //         {
  //           courseSerial: 'course1',
  //           courseName:
  //             'Junior Health, Safety, Security, and Environment (JHSEE)',
  //           numberOfTopics: 10,
  //           numberOfSubTopics: 20,
  //           completedDate: '2018-10-01 00:00:00',
  //           overralProgress: 100,
  //           preTest: 60,
  //           postTest: 70,
  //           timeSpent: 6000,
  //         },
  //         {
  //           courseSerial: 'course2',
  //           courseName: 'Value Based Development Program',
  //           numberOfTopics: 5,
  //           numberOfSubTopics: 15,
  //           completedDate: '2018-11-09 00:00:00',
  //           overralProgress: 100,
  //           preTest: 90,
  //           postTest: 92,
  //           timeSpent: 9000,
  //         },
  //       ],
  //     };
  //   }
  //   if (status === 'inprogress') {
  //     return {
  //       status: 'success',
  //       data: [
  //         {
  //           courseSerial: 'course3',
  //           courseName:
  //             'Junior Health, Safety, Security, and Environment (JHSEE) - 2',
  //           numberOfTopics: 10,
  //           numberOfSubTopics: 100,
  //           completedDate: null,
  //           overralProgress: 66,
  //           preTest: 60,
  //           postTest: null,
  //           timeSpent: 1000,
  //         },
  //       ],
  //     };
  //   }
  //   if (status === 'notstarted') {
  //     return {
  //       status: 'success',
  //       data: [
  //         {
  //           courseSerial: 'course4',
  //           courseName: 'To MR or Not to MR',
  //           numberOfTopics: 7,
  //           numberOfSubTopics: 12,
  //           completedDate: null,
  //           overralProgress: null,
  //           preTest: null,
  //           postTest: null,
  //           timeSpent: null,
  //         },
  //       ],
  //     };
  //   }
  // },
  //
  // 'ruangkerja/dashboard/user/user345/courses': (param: any) => {
  //   const {status} = param;
  //   if (status === 'completed') {
  //     return {
  //       status: 'success',
  //       data: [],
  //     };
  //   }
  //   if (status === 'inprogress') {
  //     return {
  //       status: 'success',
  //       data: [],
  //     };
  //   }
  //   if (status === 'notstarted') {
  //     return {
  //       status: 'success',
  //       data: [
  //         {
  //           courseSerial: 'course4',
  //           courseName: 'To MR or Not to MR',
  //           numberOfTopics: 7,
  //           numberOfSubTopics: 12,
  //           completedDate: null,
  //           overralProgress: null,
  //           preTest: null,
  //           postTest: null,
  //           timeSpent: null,
  //         },
  //       ],
  //     };
  //   }
  // },
  //
  // 'ruangkerja/dashboard/course-completion/chart': (params: {
  //   courseSerial: string,
  //   yearStart: string,
  //   monthStart: string,
  //   yearEnd: string,
  //   monthEnd: string,
  //   unit: 'date' | 'week' | 'month' | 'year',
  // }) => {
  //   const {courseSerial, yearStart, monthStart, yearEnd, monthEnd} = params;
  //   return {
  //     status: 'success',
  //     data: progressDataCourse,
  //   };
  // },
  //
  // 'ruangkerja/dashboard/user-completion/chart': (params: {
  //   companySerials: string,
  //   departmentSerials: string,
  //   tagSerials: string,
  //   yearStart: string,
  //   monthStart: string,
  //   yearEnd: string,
  //   monthEnd: string,
  //   unit: 'date' | 'week' | 'month' | 'year',
  // }) => {
  //   const {
  //     companySerials,
  //     departmentSerials,
  //     tagSerials,
  //     yearStart,
  //     monthStart,
  //     yearEnd,
  //     monthEnd,
  //   } = params;
  //   return {
  //     status: 'success',
  //     data: progressDataUsers,
  //   };
  // },
};
