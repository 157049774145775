'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  width: 100%;\n  border-spacing: 0;\n  border-collapse: collapse;\n  background-color: ', ';\n'], ['\n  width: 100%;\n  border-spacing: 0;\n  border-collapse: collapse;\n  background-color: ', ';\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  display: table-row-group;\n'], ['\n  display: table-row-group;\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  :hover {\n    cursor: pointer;\n  }\n'], ['\n  :hover {\n    cursor: pointer;\n  }\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  padding: 0.5rem;\n  font-size: ', ';\n  font-family: inherit;\n  text-transform: uppercase;\n  font-weight: bold;\n  font-style: normal;\n  font-stretch: normal;\n  text-align: left;\n  background-color: ', ';\n  color: ', ';\n  height: 3rem;\n  &:last-child {\n    border-top-right-radius: 0.4rem;\n  }\n  &:first-child {\n    border-top-left-radius: 0.4rem;\n  }\n'], ['\n  padding: 0.5rem;\n  font-size: ', ';\n  font-family: inherit;\n  text-transform: uppercase;\n  font-weight: bold;\n  font-style: normal;\n  font-stretch: normal;\n  text-align: left;\n  background-color: ', ';\n  color: ', ';\n  height: 3rem;\n  &:last-child {\n    border-top-right-radius: 0.4rem;\n  }\n  &:first-child {\n    border-top-left-radius: 0.4rem;\n  }\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n  padding: 0.5rem;\n  text-align: left;\n  font-family: inherit;\n  font-size: ', ';\n  height: 3.125rem;\n'], ['\n  padding: 0.5rem;\n  text-align: left;\n  font-family: inherit;\n  font-size: ', ';\n  height: 3.125rem;\n']),
    _templateObject6 = _taggedTemplateLiteral(['\n  &:nth-child(even) {\n    background-color: ', ';\n    box-shadow: 0 -1px 0 0 ', ',\n      0 1px 0 0 ', ';\n  }\n  &:hover {\n    background-color: ', ';\n  }\n  font-size: ', ';\n  cursor: ', ';\n  background-color: ', ';\n  user-select: ', ';\n  opacity: ', ';\n  pointer-events: ', ';\n'], ['\n  &:nth-child(even) {\n    background-color: ', ';\n    box-shadow: 0 -1px 0 0 ', ',\n      0 1px 0 0 ', ';\n  }\n  &:hover {\n    background-color: ', ';\n  }\n  font-size: ', ';\n  cursor: ', ';\n  background-color: ', ';\n  user-select: ', ';\n  opacity: ', ';\n  pointer-events: ', ';\n']),
    _templateObject7 = _taggedTemplateLiteral(['\n  padding: 0.5rem;\n  text-align: center;\n  font-family: inherit;\n  font-size: 1rem;\n  height: 3.125rem;\n  background-color: ', ';\n'], ['\n  padding: 0.5rem;\n  text-align: center;\n  font-family: inherit;\n  font-size: 1rem;\n  height: 3.125rem;\n  background-color: ', ';\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('../');

var _rgIcons = require('rg-icons');

var _lodash = require('lodash.isequal');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var DataTable = function (_React$Component) {
  _inherits(DataTable, _React$Component);

  _createClass(DataTable, null, [{
    key: 'getDerivedStateFromProps',
    value: function getDerivedStateFromProps(nextProps) {
      return {
        dataSource: nextProps.dataSource
      };
    }
  }]);

  function DataTable(props) {
    _classCallCheck(this, DataTable);

    var _this = _possibleConstructorReturn(this, (DataTable.__proto__ || Object.getPrototypeOf(DataTable)).call(this, props));

    _initialiseProps.call(_this);

    var _props$selectedKeys = props.selectedKeys,
        selectedKeys = _props$selectedKeys === undefined ? [] : _props$selectedKeys,
        rowSelection = props.rowSelection,
        dataSource = props.dataSource;

    _this.state = {
      selectedKeysState: selectedKeys,
      dataSource: dataSource,
      selectedAll: dataSource.length > 0 ? dataSource.filter(function (data) {
        return !data._disabledRow;
      }).every(function (data) {
        return selectedKeys.includes(data.key);
      }) : false
    };
    if (rowSelection && rowSelection.onChange) {
      _this.changeSelection(); // reset selectedKeys
    }
    return _this;
  }

  _createClass(DataTable, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps, prevState) {
      var _state = this.state,
          dataSource = _state.dataSource,
          selectedKeysState = _state.selectedKeysState;


      if (!(0, _lodash2.default)(prevState.dataSource, dataSource) || !(0, _lodash2.default)(prevState.selectedKeysState, selectedKeysState)) {
        this.setState(function () {
          return {
            selectedAll: dataSource.length > 0 ? dataSource.filter(function (data) {
              return !data._disabledRow;
            }).every(function (data) {
              return selectedKeysState.includes(data.key);
            }) : false
          };
        });
      }
    }
  }, {
    key: 'sortColumn',
    value: function sortColumn(columnIndex) {
      var dataSource = this.state.dataSource;

      var sortColumnByIndex = function sortColumnByIndex(a, b) {
        return a[columnIndex] > b[columnIndex] ? a[columnIndex] - b[columnIndex] : b[columnIndex] - a[columnIndex];
      };
      var sortDataSource = dataSource.sort(sortColumnByIndex);
      this.setState({
        dataSource: sortDataSource
      });
    }
  }, {
    key: 'render',
    value: function render() {
      var dataSource = this.state.dataSource;
      var _props = this.props,
          hasEmptyTable = _props.hasEmptyTable,
          emptyTableElement = _props.emptyTableElement;

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(
          _.Flex,
          { style: this.props.containerStyle },
          React.createElement(
            StyledTable,
            null,
            React.createElement(
              StyledThead,
              null,
              this.renderColumns()
            ),
            React.createElement(
              'tbody',
              null,
              this.renderDataSource()
            )
          )
        ),
        dataSource.length === 0 && hasEmptyTable && (emptyTableElement || React.createElement(
          StyledBox,
          null,
          React.createElement(
            _.Text,
            null,
            'Data Not Found'
          )
        ))
      );
    }
  }]);

  return DataTable;
}(React.Component);

DataTable.defaultProps = {
  currentPage: 1,
  hasEmptyTable: false
};

var _initialiseProps = function _initialiseProps() {
  var _this3 = this;

  this._getPageSelection = function () {
    var dataSource = _this3.props.dataSource;
    var selectedKeysState = _this3.state.selectedKeysState;

    var dataSourseKey = dataSource.map(function (item) {
      return item.key;
    });
    return dataSourseKey.filter(function (item) {
      return selectedKeysState.includes(item);
    });
  };

  this.changeSelection = function () {
    var selectedKeysState = _this3.state.selectedKeysState;
    var rowSelection = _this3.props.rowSelection;
    var onChange = rowSelection.onChange;

    onChange(selectedKeysState);
  };

  this.handleSelection = function (item) {
    var rowSelection = _this3.props.rowSelection;

    if (rowSelection.onChange) {
      _this3.setState(function (_ref7) {
        var selectedKeysState = _ref7.selectedKeysState;

        var isKeyAlreadyExists = selectedKeysState.includes(item.key);
        var newSelectedKeys = isKeyAlreadyExists ? selectedKeysState.filter(function (keySelected) {
          return keySelected !== item.key;
        }) : [].concat(_toConsumableArray(selectedKeysState), [item.key]);
        return {
          selectedKeysState: newSelectedKeys
        };
      }, _this3.changeSelection);
    } else if (rowSelection.onClick) {
      rowSelection.onClick(item);
    }
  };

  this.handleSelectAll = function () {
    var dataSource = _this3.props.dataSource;

    _this3.setState(function (_ref8) {
      var selectedKeysState = _ref8.selectedKeysState,
          selectedAll = _ref8.selectedAll;

      if (!selectedAll) {
        var allSelected = dataSource.filter(function (item) {
          return !item._disabledRow;
        }).map(function (item) {
          return item.key;
        }).concat(selectedKeysState);
        return {
          selectedKeysState: [].concat(_toConsumableArray(new Set(allSelected))),
          selectedAll: true
        };
      } else {
        return {
          selectedKeysState: selectedKeysState.filter(function (key) {
            return !dataSource.find(function (data) {
              return data.key === key;
            });
          }),
          selectedAll: false
        };
      }
    }, _this3.changeSelection);
  };

  this.getCheckboxProps = function (data) {
    var rowSelection = _this3.props.rowSelection;

    var checkboxProps = rowSelection ? rowSelection.getCheckboxProps ? rowSelection.getCheckboxProps(data) : null : null;
    var selectedKeys = _this3._getPageSelection();
    return _extends({
      checked: selectedKeys.indexOf(data.key) > -1,
      controlled: true
    }, checkboxProps);
  };

  this.renderColumns = function () {
    var _props2 = _this3.props,
        hasCheckbox = _props2.hasCheckbox,
        columns = _props2.columns,
        headerColor = _props2.headerColor,
        fscol = _props2.fscol;
    var selectedAll = _this3.state.selectedAll;

    var customHeaderColor = headerColor ? headerColor : '#345678';
    return React.createElement(
      StyledTr,
      null,
      hasCheckbox && React.createElement(
        StyledTh,
        { width: '2rem', bg: customHeaderColor },
        React.createElement(
          _.Flex,
          { is: _.Label, mr: 3 },
          React.createElement(_.Checkbox, {
            checked: selectedAll,
            value: selectedAll,
            onChange: _this3.handleSelectAll,
            controlled: true
          })
        )
      ),
      columns.map(function (column) {
        return React.createElement(
          StyledTh,
          {
            fscol: fscol,
            key: column.dataIndex,
            style: column.style,
            bg: customHeaderColor },
          column.sortable ? React.createElement(
            StyledSortableHeaderFlex,
            {
              alignItems: 'center',
              onClick: function onClick() {
                column.onHeaderClick ? column.onHeaderClick() : _this3.sortColumn(column.dataIndex);
              } },
            React.createElement(
              React.Fragment,
              null,
              column.title
            ),
            React.createElement(
              _.Flex,
              { ml: '0.5rem', alignItems: 'center' },
              React.createElement(_rgIcons.Icon, {
                width: '32px',
                height: '19.2px',
                name: 'triangleUpdownOutline'
              })
            )
          ) : column.title
        );
      })
    );
  };

  this.renderDataSource = function () {
    var _props3 = _this3.props,
        hasCheckbox = _props3.hasCheckbox,
        rowSelection = _props3.rowSelection,
        columns = _props3.columns,
        fsrow = _props3.fsrow;
    var dataSource = _this3.state.dataSource;


    return React.createElement(
      React.Fragment,
      null,
      dataSource.map(function (data) {
        return React.createElement(
          StyledTr,
          {
            key: data.renderKey || data.key,
            onClick: function onClick() {
              if (rowSelection && rowSelection.onClick) {
                _this3.handleSelection(data);
              }
            },
            cursor: rowSelection ? rowSelection.onClick ? 'pointer' : 'auto' : 'auto',
            disabled: data._disabledRow },
          hasCheckbox && React.createElement(
            StyledTd,
            null,
            React.createElement(
              _.Flex,
              { is: _.Label, mr: 3 },
              React.createElement(_.Checkbox, _extends({
                disabled: data._disabledRow,
                onChange: function onChange() {
                  if (rowSelection && rowSelection.onChange) {
                    _this3.handleSelection(data);
                  }
                }
              }, _this3.getCheckboxProps(data)))
            )
          ),
          columns && columns.map(function (column) {
            return React.createElement(
              StyledTd,
              { fsrow: fsrow, key: column.dataIndex },
              data[column.dataIndex] && data[column.dataIndex].render ? data[column.dataIndex].render() : data[column.dataIndex]
            );
          })
        );
      })
    );
  };
};

var StyledTable = _styledComponents2.default.table(_templateObject, function (props) {
  return props.theme.colors.white;
});

var StyledThead = _styledComponents2.default.thead(_templateObject2);

var StyledSortableHeaderFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject3);

var StyledTh = _styledComponents2.default.th(_templateObject4, function (props) {
  return !!props.fscol && props.fscol !== '' ? props.fscol : '0.875rem';
}, function (props) {
  return props.bg;
}, function (props) {
  return props.theme.colors.white;
});

var StyledTd = _styledComponents2.default.td(_templateObject5, function (props) {
  return !!props.fsrow && props.fsrow !== '' ? props.fsrow : '1rem';
});

var StyledTr = _styledComponents2.default.tr(_templateObject6, function (props) {
  return props.theme.colors.softBrown;
}, function (props) {
  return props.theme.colors.alto;
}, function (props) {
  return props.theme.colors.alto;
}, function (_ref) {
  var theme = _ref.theme;
  return theme.colors.whiteIce;
}, function (props) {
  return !!props.fsrow && props.fsrow !== '' ? props.fs : '1rem';
}, function (_ref2) {
  var cursor = _ref2.cursor,
      disabled = _ref2.disabled;
  return disabled ? 'not-allowed' : cursor;
}, function (_ref3) {
  var disabled = _ref3.disabled;
  return disabled && '#a0a0a0 !important';
}, function (_ref4) {
  var disabled = _ref4.disabled;
  return disabled && 'none';
}, function (_ref5) {
  var disabled = _ref5.disabled;
  return disabled && '0.7';
}, function (_ref6) {
  var disabled = _ref6.disabled;
  return disabled && 'none';
});

var StyledBox = (0, _styledComponents2.default)(_.Box)(_templateObject7, function (props) {
  return props.theme.colors.white;
});

var createRefreshableDataTable = function createRefreshableDataTable(Component) {
  var tableKey = 0;

  var RefreshableDataTable = function (_React$Component2) {
    _inherits(RefreshableDataTable, _React$Component2);

    function RefreshableDataTable() {
      _classCallCheck(this, RefreshableDataTable);

      return _possibleConstructorReturn(this, (RefreshableDataTable.__proto__ || Object.getPrototypeOf(RefreshableDataTable)).apply(this, arguments));
    }

    _createClass(RefreshableDataTable, [{
      key: 'render',
      value: function render() {
        return React.createElement(Component, _extends({ key: tableKey }, this.props));
      }
    }], [{
      key: 'refresh',
      value: function refresh() {
        tableKey++;
      }
    }]);

    return RefreshableDataTable;
  }(React.Component);

  return RefreshableDataTable;
};

exports.default = createRefreshableDataTable(DataTable);