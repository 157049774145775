'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  text-transform: uppercase;\n  text-decoration: none;\n  line-height: 1;\n'], ['\n  text-transform: uppercase;\n  text-decoration: none;\n  line-height: 1;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ = require('../..');

var _theme = require('../theme');

var _rgIcons = require('rg-icons');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var BreadCrumb = function BreadCrumb(_ref) {
  var bg = _ref.bg,
      data = _ref.data,
      _ref$type = _ref.type,
      type = _ref$type === undefined ? 'ruker-cms' : _ref$type,
      _ref$customItemOnPres = _ref.customItemOnPress,
      customItemOnPress = _ref$customItemOnPres === undefined ? function () {} : _ref$customItemOnPres;

  var containerPT = void 0;
  var containerPB = void 0;
  var containerPL = void 0;
  var containerPR = void 0;

  switch (type) {
    case 'ruker-cms':
      containerPT = 2;
      containerPB = 2;
      containerPL = 4;
      containerPR = 4;
      break;
    case 'ruker-dashboard':
      containerPT = '0.625rem';
      containerPB = '0.625rem';
      containerPL = '1.3125rem';
      containerPR = '2.5625rem';
      break;
    default:
      containerPT = 2;
      containerPB = 2;
      containerPL = 4;
      containerPR = 4;
  }

  return React.createElement(
    _.Flex,
    {
      alignItems: 'center',
      bg: bg ? bg : 'prussianBlue',
      pt: containerPT,
      pb: containerPB,
      pr: containerPR,
      pl: containerPL },
    data && data.map(function (item, index) {
      return React.createElement(
        _.Flex,
        { alignItems: 'center', mr: 2, key: item.name },
        React.createElement(
          _.Box,
          { onClick: function onClick() {
              return customItemOnPress(item.path);
            } },
          React.createElement(
            StyledBreadCrumbTextLink,
            {
              fontSize: 1,
              mr: 2,
              color: _theme.colors.white,
              to: item.path,
              bold: index === data.length - 1,
              is: item.is },
            item.name
          )
        ),
        index !== data.length - 1 ? React.createElement(_rgIcons.Icon, { name: 'angleRight', fill: _theme.colors.white, size: '12' }) : null
      );
    })
  );
};

var StyledBreadCrumbTextLink = (0, _styledComponents2.default)(_.Link)(_templateObject);

exports.default = BreadCrumb;