'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  background-color: ', ';\n  overflow: hidden;\n  border-bottom: solid 0.1rem ', ';\n  margin: 0;\n  padding-top: 36px;\n'], ['\n  background-color: ', ';\n  overflow: hidden;\n  border-bottom: solid 0.1rem ', ';\n  margin: 0;\n  padding-top: 36px;\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  position: fixed;\n  background-color: white;\n  top: 0;\n  z-index: 10;\n  border-bottom: ', ';\n'], ['\n  position: fixed;\n  background-color: white;\n  top: 0;\n  z-index: 10;\n  border-bottom: ', ';\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  float: right;\n  text-transform: capitalize;\n'], ['\n  float: right;\n  text-transform: capitalize;\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  clear: both;\n  overflow: hidden;\n'], ['\n  clear: both;\n  overflow: hidden;\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n  float: left;\n  letter-spacing: 0.1rem;\n'], ['\n  float: left;\n  letter-spacing: 0.1rem;\n']),
    _templateObject6 = _taggedTemplateLiteral(['\n  float: right;\n'], ['\n  float: right;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('../');

var _rgIcons = require('rg-icons');

var _commonType = require('../commonType');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function Header(_ref) {
  var hideHeaderFrame = _ref.hideHeaderFrame,
      hideButtonBack = _ref.hideButtonBack,
      customBackButton = _ref.customBackButton,
      title = _ref.title,
      buttonRight = _ref.buttonRight,
      children = _ref.children,
      _ref$type = _ref.type,
      type = _ref$type === undefined ? 'ruker-cms' : _ref$type,
      backButtonTo = _ref.backButtonTo,
      backButtonIs = _ref.backButtonIs,
      history = _ref.history;

  var headerFrameMB = void 0,
      headerFramePL = void 0,
      headerFramePR = void 0,
      headerFramePT = void 0,
      headerFramePB = void 0,
      headerBodyPT = void 0,
      headerBodyPB = void 0,
      buttonPX = void 0,
      buttonPY = void 0,
      buttonMT = void 0,
      showBackButtonBorder = void 0;

  switch (type) {
    case 'ruker-cms':
      headerFrameMB = 2;
      headerFramePL = 4;
      headerFramePR = 4;
      headerFramePT = 0;
      headerFramePB = 3;
      headerBodyPT = hideButtonBack === true ? 0 : '2rem';
      headerBodyPB = '0.5rem';
      buttonPX = '0';
      buttonPY = '1rem';
      buttonMT = '0';
      showBackButtonBorder = false;
      break;
    case 'ruker-dashboard':
      headerFrameMB = '0';
      headerFramePL = '1.3125rem';
      headerFramePR = '2.5625rem';
      headerFramePT = hideButtonBack === true ? '1.625rem' : '0';
      headerFramePB = hideButtonBack === true ? '1.25rem' : '0';
      headerBodyPT = '0';
      headerBodyPB = '0';
      buttonPX = '0';
      buttonPY = '0.5rem';
      buttonMT = '0';
      showBackButtonBorder = true;
      break;
    default:
      headerFrameMB = 2;
      headerFramePL = 4;
      headerFramePR = 4;
      headerFramePT = 3;
      headerFramePB = 3;
      headerBodyPT = '2rem';
      headerBodyPB = '0.5rem';
      buttonPX = '0';
      buttonPY = '0';
      buttonMT = '0';
      showBackButtonBorder = true;
  }

  return React.createElement(
    React.Fragment,
    null,
    !hideHeaderFrame && React.createElement(
      StyledHeaderFrameBox,
      {
        mb: headerFrameMB,
        pt: headerFramePT,
        pb: headerFramePB,
        pr: headerFramePR,
        pl: 0,
        width: '100%' },
      hideButtonBack ? null : React.createElement(
        StyledStickyBox,
        {
          width: '100%',
          ml: '0',
          mt: buttonMT,
          showbackbuttonborder: showBackButtonBorder === true ? 1 : 0 },
        React.createElement(
          _.Button,
          {
            onClick: function onClick() {
              return !backButtonTo ? history ? history.goBack() : window.history.back() : {};
            },
            is: backButtonIs,
            to: backButtonTo,
            ml: headerFramePL,
            px: buttonPX,
            py: buttonPY,
            bg: 'transparent',
            color: '#425981' },
          React.createElement(
            _.Flex,
            { alignItems: 'center' },
            React.createElement(_rgIcons.Icon, { name: 'chevronLeftOutline', height: '20px', width: '20px' }),
            React.createElement(
              StyledTextIconText,
              { pl: '0.375rem', fontSize: '1.25rem' },
              'Back'
            )
          )
        )
      ),
      (title || buttonRight) && React.createElement(
        StyledHeaderBodyBox,
        {
          pt: headerBodyPT,
          pb: headerBodyPB,
          pl: headerFramePL },
        React.createElement(
          StyledHeaderTitleText,
          { fontSize: '2rem' },
          title
        ),
        React.createElement(
          StyledFloatRightBox,
          null,
          buttonRight
        )
      )
    ),
    children && React.createElement(
      _.Box,
      null,
      children
    )
  );
}

var StyledHeaderFrameBox = (0, _styledComponents2.default)(_.Box)(_templateObject, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.strongSilver;
});

var StyledStickyBox = (0, _styledComponents2.default)(_.Box)(_templateObject2, function (props) {
  if (props.showbackbuttonborder === 1) {
    return 'solid 0.5px ' + props.theme.colors.strongSilver;
  } else {
    return 'none';
  }
});

var StyledTextIconText = (0, _styledComponents2.default)(_.Text)(_templateObject3);

var StyledHeaderBodyBox = (0, _styledComponents2.default)(_.Box)(_templateObject4);

var StyledHeaderTitleText = (0, _styledComponents2.default)(_.Text)(_templateObject5);

var StyledFloatRightBox = (0, _styledComponents2.default)(_.Box)(_templateObject6);

exports.default = Header;