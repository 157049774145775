'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  color: ', ';\n  height: 100%;\n  background-image: ', ';\n  overflow: auto;\n'], ['\n  color: ', ';\n  height: 100%;\n  background-image: ', ';\n  overflow: auto;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('..');

var _theme = require('../theme');

var _commonType = require('../commonType');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var Sidebar = function Sidebar(props) {
  return React.createElement(SidebarStyled, props);
};

function getBg(_ref) {
  var bg = _ref.bg;

  var _bg = bg ? bg : 'linear-gradient(to bottom, ' + _theme.colors.fiord + ', ' + _theme.colors.biscay + ')';

  return _bg;
}

// use fixed. Next use position sticky
var SidebarStyled = (0, _styledComponents2.default)(_.Fixed)(_templateObject, function (props) {
  return props.color ? props.color : 'white';
}, function (props) {
  return getBg(props);
});

exports.default = Sidebar;