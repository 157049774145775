'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  display: inline-block;\n'], ['\n  display: inline-block;\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n'], ['\n  border-top-left-radius: 4px;\n  border-top-right-radius: 4px;\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  display: ', ';\n  border: 1px solid ', ';\n  border-radius: 4px;\n  ', ';\n  &:before,\n  &:after {\n    content: \' \';\n    border: solid transparent;\n    height: 0;\n    width: 0;\n    position: absolute;\n    pointer-events: none;\n    ', ';\n  }\n  &:after {\n    border-color: rgba(255, 255, 255, 0);\n    border-width: 10px;\n    ', ';\n  }\n  &:before {\n    border-color: rgba(255, 225, 245, 0);\n    border-width: 11px;\n    ', ';\n  }\n'], ['\n  display: ', ';\n  border: 1px solid ', ';\n  border-radius: 4px;\n  ', ';\n  &:before,\n  &:after {\n    content: \' \';\n    border: solid transparent;\n    height: 0;\n    width: 0;\n    position: absolute;\n    pointer-events: none;\n    ', ';\n  }\n  &:after {\n    border-color: rgba(255, 255, 255, 0);\n    border-width: 10px;\n    ', ';\n  }\n  &:before {\n    border-color: rgba(255, 225, 245, 0);\n    border-width: 11px;\n    ', ';\n  }\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  position: relative;\n  width: fit-content;\n'], ['\n  position: relative;\n  width: fit-content;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _rgIcons = require('rg-icons');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _recompose = require('recompose');

var _ = require('..');

var _util = require('../util');

var _unstable = require('../unstable');

var _theme = require('../theme');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var TooltipBase = function TooltipBase(props) {
  return _react2.default.createElement(
    Tool,
    { innerRef: props.onRef },
    _react2.default.createElement(
      StyledTrigger,
      {
        z: '2',
        onClick: props.mode && props.mode === 'hover' ? function () {} : props.toggle,
        onMouseEnter: props.mode && props.mode === 'hover' ? props.toggle : function () {},
        onMouseLeave: props.mode && props.mode === 'hover' ? props.toggle : function () {} },
      props.children
    ),
    _react2.default.createElement(
      Tip,
      {
        open: props.open,
        w: props.w ? props.w : '300px',
        bg: 'white',
        direction: props.direction,
        z: '20',
        tipcolor: props.tipcolor || '#fff' },
      _react2.default.createElement(
        _.Relative,
        null,
        _react2.default.createElement(
          StyledLead,
          { fontSize: 1, px: 3, py: 1, bg: 'catskillWhite', bold: true },
          'INFORMATION'
        ),
        _react2.default.createElement(
          _.Box,
          { px: 3, py: 2 },
          _react2.default.createElement(
            _.Text,
            { fontSize: 2 },
            props.text
          )
        )
      )
    )
  );
};

var StyledTrigger = (0, _styledComponents2.default)(_.Relative)(_templateObject);

var StyledLead = (0, _styledComponents2.default)(_.Lead)(_templateObject2);

var Tip = (0, _styledComponents2.default)(_.Absolute)(_templateObject3, function (props) {
  return props.open ? 'block' : 'none';
}, function (props) {
  return (0, _util.color)(props)('blue');
}, function (props) {
  switch (props.direction) {
    case 'top':
      return 'top: -10px; left: 20px; transform: translateY(calc(-100% - 10px)) translateX(-40px)';
    case 'right':
      return 'left: 40px; top: 50%; transform: translateY(-50%)';
    case 'left':
      return 'right: 40px; top: 50%; transform: translateY(-50%)';
    case 'bottom':
      return 'bottom: -10px; left: 20px; transform: translateY(calc(100% + 10px)) translateX(-40px)';
    case 'top-right':
      return 'left: 40px; top: 0; transform: translateY(calc(-100% + 10px))';
    case 'bottom-right':
      return 'left: 40px; bottom: 0; transform: translateY(calc(100% - 10px))';
    case 'top-left':
      return 'right: 40px; top: 0; transform: translateY(calc(-100% + 10px))';
    case 'bottom-left':
      return 'right: 40px; bottom: 0; transform: translateY(calc(100% - 10px))';
    default:
      return 'top: -10px; left: 20px; transform: translateY(calc(-100% - 10px)) translateX(-40px)';
  }
}, function (props) {
  switch (props.direction) {
    case 'top':
      return 'top: 100%; left: 26px';
    case 'right':
      return 'right: 100%; top: 50%';
    case 'left':
      return 'left: 100%; top: 50%';
    case 'bottom':
      return 'left: 26px; bottom: 100%';
    default:
      return 'top: 100%; left: 26px';
  }
}, function (props) {
  var tipColor = props.tipcolor;

  switch (props.direction) {
    case 'top':
      return 'border-top-color: ' + tipColor + '; margin-left: -10px;';
    case 'right':
      return 'border-right-color: ' + tipColor + '; margin-top: -10px';
    case 'left':
      return 'border-left-color: ' + tipColor + '; margin-top: -10px';
    case 'bottom':
      return 'border-bottom-color: ' + tipColor + '; margin-left: -10px';
    case 'top-right':
      return 'border-bottom-color: ' + tipColor + '; top: 100%; margin-top: -20px; left: -10px';
    case 'bottom-right':
      return 'border-top-color: ' + tipColor + '; top: 0; left: -10px';
    case 'top-left':
      return 'border-bottom-color: ' + tipColor + '; top: auto; bottom: 0; margin-left: -10px; left: 100%;';
    case 'bottom-left':
      return 'border-top-color: ' + tipColor + '; top: 0; left: 100%; margin-left: -10px;';
    default:
      return 'border-top-color: ' + tipColor + '; margin-left: -10px;';
  }
}, function (props) {
  switch (props.direction) {
    case 'top':
      return 'border-top-color: ' + _theme.colors.blue + '; margin-left: -11px;';
    case 'right':
      return 'border-right-color: ' + _theme.colors.blue + '; margin-top: -11px';
    case 'left':
      return 'border-left-color: ' + _theme.colors.blue + '; margin-top: -11px';
    case 'bottom':
      return 'border-bottom-color: ' + _theme.colors.blue + '; margin-left: -11px';
    case 'top-right':
      return 'border-bottom-color: ' + _theme.colors.blue + '; left: -12px; margin-top: -21px; ';
    case 'bottom-right':
      return 'border-top-color: ' + _theme.colors.blue + '; top: -1px; left: -12px';
    case 'top-left':
      return 'border-bottom-color: ' + _theme.colors.blue + '; top: auto; bottom: -1px; margin-left: -10px; left: 100%;';
    case 'bottom-left':
      return 'border-top-color: ' + _theme.colors.blue + '; top: -1px; left: 100%; margin-left: -10px';
    default:
      return 'border-top-color: ' + _theme.colors.blue + '; margin-left: -11px;';
  }
});

var Tool = _styledComponents2.default.div(_templateObject4);

exports.default = (0, _recompose.compose)(_unstable.withToggle, (0, _recompose.withProps)(function (_ref) {
  var setOpen = _ref.setOpen;
  return {
    onClickOutside: function onClickOutside() {
      return setOpen(false);
    }
  };
}), _unstable.withClickOutside)(TooltipBase);