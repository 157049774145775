'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  height: 5rem;\n'], ['\n  height: 5rem;\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  padding: 1rem;\n  border-radius: 10rem 0 0 10rem;\n  border-left: solid 0.05rem ', ';\n  border-top: solid 0.05rem ', ';\n  border-bottom: solid 0.05rem ', ';\n  background-color: ', ';\n  color: ', ';\n'], ['\n  padding: 1rem;\n  border-radius: 10rem 0 0 10rem;\n  border-left: solid 0.05rem ', ';\n  border-top: solid 0.05rem ', ';\n  border-bottom: solid 0.05rem ', ';\n  background-color: ', ';\n  color: ', ';\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  padding: 1rem;\n  border-radius: 0 10rem 10rem 0;\n  border: solid 0.05rem ', ';\n  background-color: ', ';\n  color: ', ';\n'], ['\n  padding: 1rem;\n  border-radius: 0 10rem 10rem 0;\n  border: solid 0.05rem ', ';\n  background-color: ', ';\n  color: ', ';\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  padding: 1rem;\n  padding-bottom: 1.05rem;\n  border-radius: 0;\n  border-top: solid 0.05rem ', ';\n  border-bottom: solid 0.05rem ', ';\n  border-left: solid 0.05rem ', ';\n  background-color: ', ';\n  color: ', ';\n'], ['\n  padding: 1rem;\n  padding-bottom: 1.05rem;\n  border-radius: 0;\n  border-top: solid 0.05rem ', ';\n  border-bottom: solid 0.05rem ', ';\n  border-left: solid 0.05rem ', ';\n  background-color: ', ';\n  color: ', ';\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n  color: ', ';\n  font-weight: 900;\n'], ['\n  color: ', ';\n  font-weight: 900;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ = require('../');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _rgIcons = require('rg-icons');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

var Pagination = function Pagination(props) {
  var currentPage = props.currentPage,
      totalPage = props.totalPage,
      onClick = props.onClick;


  var displayArray = [].concat(_toConsumableArray(Array(10))).reduce(function (accumulator, data, index) {
    if (totalPage <= 10) {
      if (index < totalPage) {
        accumulator.push(index + 1);
      }
    } else {
      if (currentPage <= 6) {
        if (index <= 6) {
          accumulator.push(index + 1);
        }
        if (index === 7) {
          accumulator.push(0);
        }
        if (index === 8) {
          accumulator.push(totalPage);
        }
      } else if (totalPage - currentPage > 4) {
        if (index === 0) {
          accumulator.push(index + 1);
        }
        if (index === 1 || index === 8) {
          accumulator.push(0);
        }
        if (index === 9) {
          accumulator.push(totalPage);
        }
        if (index >= 3 && index <= 7) {
          accumulator.push(index + currentPage - 5);
        }
      } else {
        if (index === 0) {
          accumulator.push(index + 1);
        }
        if (index === 1) {
          accumulator.push(0);
        }
        if (index >= 3) {
          accumulator.push(index + totalPage - 9);
        }
      }
    }

    return accumulator;
  }, []);

  return React.createElement(
    StyledPaginationWrapperFlex,
    {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center' },
    React.createElement(
      StyledPreviousButton,
      {
        onClick: function (_onClick) {
          function onClick() {
            return _onClick.apply(this, arguments);
          }

          onClick.toString = function () {
            return _onClick.toString();
          };

          return onClick;
        }(function () {
          return onClick(currentPage - 1, totalPage);
        }),
        disabled: currentPage <= 1 },
      React.createElement(_rgIcons.Icon, { name: 'arrowLeft' })
    ),
    displayArray.map(function (page, index) {
      var isActive = page === currentPage;

      return React.createElement(
        StyledPageButton,
        {
          key: index,
          onClick: page !== 0 ? function () {
            return onClick(page, totalPage);
          } : function () {},
          active: isActive },
        isActive ? React.createElement(
          StyledActivePageText,
          null,
          page
        ) : page === 0 ? '...' : page
      );
    }),
    React.createElement(
      StyledNextButton,
      {
        onClick: function (_onClick2) {
          function onClick() {
            return _onClick2.apply(this, arguments);
          }

          onClick.toString = function () {
            return _onClick2.toString();
          };

          return onClick;
        }(function () {
          return onClick(currentPage + 1, totalPage);
        }),
        disabled: currentPage >= totalPage },
      React.createElement(_rgIcons.Icon, { name: 'arrowRight' })
    )
  );
};

var StyledPaginationWrapperFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject);

var StyledPreviousButton = (0, _styledComponents2.default)(_.Button)(_templateObject2, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.black;
});

var StyledNextButton = (0, _styledComponents2.default)(_.Button)(_templateObject3, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.black;
});

var StyledPageButton = (0, _styledComponents2.default)(_.Button)(_templateObject4, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.border;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.colors.black;
});

var StyledActivePageText = (0, _styledComponents2.default)(_.Text)(_templateObject5, function (props) {
  return props.theme.colors.primary;
});

exports.default = Pagination;