'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _ramda = require('ramda');

var _ = require('..');

var _recompose = require('recompose');

var _EmptyScreen = require('./EmptyScreen');

var _EmptyScreen2 = _interopRequireDefault(_EmptyScreen);

var _Spinner = require('./Spinner');

var _Spinner2 = _interopRequireDefault(_Spinner);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var withDefaultProps = (0, _recompose.defaultProps)({
  data: [],
  loading: false,
  isComponent: true,
  isError: false
}); //missing | instanceof Error | empty List | default (non-empty list)


var withLoadingState = (0, _recompose.branch)(function (props) {
  return props.loading;
}, (0, _recompose.renderComponent)(_Spinner2.default));

var withEmptyState = (0, _recompose.branch)(function (props) {
  var data = !props.data || props.data.length === 0 || (0, _ramda.isEmpty)(Object.keys(props.data));
  return data;
}, (0, _recompose.renderComponent)(function (_ref) {
  var empty = _ref.empty,
      isComponent = _ref.isComponent;

  if (isComponent) {
    if (empty.title || empty.image || empty.button) console.error('You use wrong empty prop. change the value to React Component or React Node');
    return React.createElement(
      'div',
      null,
      !empty.title && empty
    );
  } else {
    return React.createElement(_EmptyScreen2.default, empty);
  }
}));

var withErrorState = (0, _recompose.branch)(function (props) {
  return props.isError;
}, (0, _recompose.renderComponent)(function (_ref2) {
  var errorView = _ref2.errorView;

  if (errorView) {
    return React.createElement(
      'div',
      null,
      errorView
    );
  } else {
    return React.createElement(
      _.Card,
      { p: 3 },
      React.createElement(
        _.Flex,
        { flexDirection: 'column', alignItems: 'center' },
        React.createElement(
          _.Text,
          null,
          'Sorry, an error has occured.'
        ),
        React.createElement(
          _.Text,
          null,
          'We are working on fixing it right now'
        )
      )
    );
  }
}));

exports.default = (0, _recompose.compose)(withDefaultProps, withLoadingState, withErrorState, withEmptyState);