'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  display: ', ';\n'], ['\n  display: ', ';\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  overflow: auto;\n'], ['\n  overflow: auto;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ = require('..');

var _unstable = require('../unstable');

var _SidebarMenuParent = require('./SidebarMenuParent');

var _SidebarMenuParent2 = _interopRequireDefault(_SidebarMenuParent);

var _SidebarMenuChild = require('./SidebarMenuChild');

var _SidebarMenuChild2 = _interopRequireDefault(_SidebarMenuChild);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _utils = require('utils');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var isParentMatch = function isParentMatch(path, currentPath) {
  var firstPath = path && path.split('/')[1];
  var firstCurrentPath = currentPath && currentPath.split('/')[1];

  if (!firstPath || !firstCurrentPath) {
    return;
  } else {
    return firstCurrentPath.startsWith(firstPath);
  }
};

var SidebarMenu = function SidebarMenu(props) {
  return React.createElement(
    StyledSidebarMenuContainerFlex,
    { flexDirection: 'column' },
    props.list.map(function (item, index) {
      if ((0, _utils.ensureArray)(item.child).length <= 0) {
        var url = item.parent.props.to || item.parent.props.href;
        return React.createElement(_SidebarMenuParent2.default, {
          children: item.parent,
          childprop: item.parent.props.children,
          key: 'parent-' + index,
          isActive: isParentMatch(url, props.match),
          productname: props.productname ? props.productname : 'ruker'
        });
      }

      return React.createElement(ParentToggle, {
        key: 'collapse-' + index,
        item: item,
        match: props.match,
        productname: props.productname
      });
    })
  );
};

var NestedMenu = function (_React$Component) {
  _inherits(NestedMenu, _React$Component);

  function NestedMenu(props) {
    _classCallCheck(this, NestedMenu);

    var _this = _possibleConstructorReturn(this, (NestedMenu.__proto__ || Object.getPrototypeOf(NestedMenu)).call(this, props));

    var _this$props = _this.props,
        item = _this$props.item,
        match = _this$props.match,
        toggle = _this$props.toggle,
        open = _this$props.open;

    var url = item.parent.props.to || item.parent.props.href;
    var isActive = isParentMatch(url, match);
    _this.state = {
      isOpen: isActive
    };

    if (isActive && !open) {
      toggle();
    }
    return _this;
  }

  _createClass(NestedMenu, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var open = this.props.open;

      if (open !== prevProps.open) {
        this.setState({ isOpen: open });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props = this.props,
          toggle = _props.toggle,
          item = _props.item,
          match = _props.match,
          productname = _props.productname;
      var isOpen = this.state.isOpen;

      var url = item.parent.props.to || item.parent.props.href;
      return React.createElement(
        _.Box,
        null,
        React.createElement(_SidebarMenuParent2.default, {
          children: item.parent,
          childprop: item.parent.props.children,
          onClick: toggle,
          open: isOpen,
          isActive: isParentMatch(url, match),
          haschildren: 1,
          productname: productname ? productname : 'ruker'
        }),
        React.createElement(
          Nested,
          { w: '100%', open: isOpen },
          item.child && item.child.map(function (childItem, index) {
            return React.createElement(_SidebarMenuChild2.default, {
              key: 'collapse-child_' + index,
              children: childItem,
              productname: productname ? productname : 'ruker'
            });
          })
        )
      );
    }
  }]);

  return NestedMenu;
}(React.Component);

var Nested = (0, _styledComponents2.default)(_.Box)(_templateObject, function (props) {
  return props.open ? 'block' : 'none';
});
var ParentToggle = (0, _unstable.withToggle)(NestedMenu);

var StyledSidebarMenuContainerFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject2);

exports.default = SidebarMenu;