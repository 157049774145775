'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _propTypes = require('prop-types');

var _propTypes2 = _interopRequireDefault(_propTypes);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('..');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var EmptyScreen = function (_React$Component) {
  _inherits(EmptyScreen, _React$Component);

  function EmptyScreen() {
    _classCallCheck(this, EmptyScreen);

    return _possibleConstructorReturn(this, (EmptyScreen.__proto__ || Object.getPrototypeOf(EmptyScreen)).apply(this, arguments));
  }

  _createClass(EmptyScreen, [{
    key: 'render',
    value: function render() {
      var _props = this.props,
          children = _props.children,
          title = _props.title,
          image = _props.image,
          description = _props.description,
          button = _props.button;


      return React.createElement(
        _.Card,
        _extends({ p: 3, py: 5, w: 1, justify: 'center' }, this.props),
        React.createElement(
          _.Flex,
          { flexDirection: 'column', align: 'center' },
          React.createElement(_.Image, { src: image, w: 200, mb: 4 }),
          React.createElement(
            _.Lead,
            { color: 'grey', bold: true, mb: 3, fontSize: 2 },
            title
          ),
          React.createElement(
            _.Text,
            { fontSize: 2, color: 'grey', align: 'center', mb: 4, w: '60%' },
            description
          ),
          button
        )
      );
    }
  }]);

  return EmptyScreen;
}(React.Component);

EmptyScreen.contextTypes = {
  manifest: _propTypes2.default.object
};
exports.default = EmptyScreen;