'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Spinner = undefined;

var _templateObject = _taggedTemplateLiteral(['\n  text-align: center;\n  svg path,\n  svg rect {\n    fill: ', ';\n  }\n'], ['\n  text-align: center;\n  svg path,\n  svg rect {\n    fill: ', ';\n  }\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _svgInlineReact = require('svg-inline-react');

var _svgInlineReact2 = _interopRequireDefault(_svgInlineReact);

var _theme = require('../../theme');

var _ = require('../..');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var spinnerSvg = '\n<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"\nwidth="24px" height="30px" viewBox="0 0 24 30" style="enable-background:new 0 0 50 50;" xml:space="preserve">\n<rect x="0" y="13" width="4" height="5" fill="#333">\n <animate attributeName="height" attributeType="XML"\n   values="5;21;5" \n   begin="0s" dur="0.6s" repeatCount="indefinite" />\n <animate attributeName="y" attributeType="XML"\n   values="13; 5; 13"\n   begin="0s" dur="0.6s" repeatCount="indefinite" />\n</rect>\n<rect x="10" y="13" width="4" height="5" fill="#333">\n <animate attributeName="height" attributeType="XML"\n   values="5;21;5" \n   begin="0.15s" dur="0.6s" repeatCount="indefinite" />\n <animate attributeName="y" attributeType="XML"\n   values="13; 5; 13"\n   begin="0.15s" dur="0.6s" repeatCount="indefinite" />\n</rect>\n<rect x="20" y="13" width="4" height="5" fill="#333">\n <animate attributeName="height" attributeType="XML"\n   values="5;21;5" \n   begin="0.3s" dur="0.6s" repeatCount="indefinite" />\n <animate attributeName="y" attributeType="XML"\n   values="13; 5; 13"\n   begin="0.3s" dur="0.6s" repeatCount="indefinite" />\n</rect>\n</svg>';

var Spinner = exports.Spinner = function Spinner(_ref) {
  var color = _ref.color;

  return React.createElement(
    StyledWrapperBox,
    { color: color },
    React.createElement(_svgInlineReact2.default, { src: spinnerSvg })
  );
};

var StyledWrapperBox = (0, _styledComponents2.default)(_.Box)(_templateObject, function (props) {
  return props.color || _theme.colors.matisse;
});