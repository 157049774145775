'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.EnhancedMultiSelect = undefined;

var _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; };

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  position: absolute;\n  border-left: 1px solid ', ';\n  right: 0;\n  top: 1px;\n  height: calc(100% - 2px);\n  pointer-events: none;\n'], ['\n  position: absolute;\n  border-left: 1px solid ', ';\n  right: 0;\n  top: 1px;\n  height: calc(100% - 2px);\n  pointer-events: none;\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  width: 10px;\n  height: 10px;\n  fill: ', ';\n'], ['\n  width: 10px;\n  height: 10px;\n  fill: ', ';\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  cursor: pointer;\n'], ['\n  cursor: pointer;\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  padding: 4px 6px;\n  align-items: center;\n  height: 34.4px;\n  box-shadow: 0 0 0 1px\n    ', ';\n  border-radius: 4px;\n  padding-right: 32px;\n  text-transform: capitalize;\n'], ['\n  padding: 4px 6px;\n  align-items: center;\n  height: 34.4px;\n  box-shadow: 0 0 0 1px\n    ', ';\n  border-radius: 4px;\n  padding-right: 32px;\n  text-transform: capitalize;\n']),
    _templateObject5 = _taggedTemplateLiteral(['\n  z-index: 1000;\n  position: absolute;\n  bottom: ', ';\n  top: ', ';\n  left: ', ';\n  right: ', ';\n  min-width: 345px;\n  width: 100%;\n  background-color: ', ';\n  padding: 10px;\n  margin-top: 4px;\n  max-height: 400px;\n  border-radius: 4px;\n  overflow-x: hidden;\n\n  ::-webkit-scrollbar {\n    width: 6px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background: #ffffff;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: #888;\n  }\n\n  ::-webkit-scrollbar-thumb:hover {\n    background: #555;\n  }\n  border: solid 1px ', ';\n'], ['\n  z-index: 1000;\n  position: absolute;\n  bottom: ', ';\n  top: ', ';\n  left: ', ';\n  right: ', ';\n  min-width: 345px;\n  width: 100%;\n  background-color: ', ';\n  padding: 10px;\n  margin-top: 4px;\n  max-height: 400px;\n  border-radius: 4px;\n  overflow-x: hidden;\n\n  ::-webkit-scrollbar {\n    width: 6px;\n  }\n\n  ::-webkit-scrollbar-track {\n    background: #ffffff;\n  }\n\n  ::-webkit-scrollbar-thumb {\n    background: #888;\n  }\n\n  ::-webkit-scrollbar-thumb:hover {\n    background: #555;\n  }\n  border: solid 1px ', ';\n']),
    _templateObject6 = _taggedTemplateLiteral(['\n  cursor: pointer;\n  margin: 0 -10px;\n'], ['\n  cursor: pointer;\n  margin: 0 -10px;\n']),
    _templateObject7 = _taggedTemplateLiteral(['\n  cursor: pointer;\n  padding: 16px 10px;\n  :hover {\n    background-color: ', ';\n  }\n  border-top: 1px solid ', ';\n'], ['\n  cursor: pointer;\n  padding: 16px 10px;\n  :hover {\n    background-color: ', ';\n  }\n  border-top: 1px solid ', ';\n']),
    _templateObject8 = _taggedTemplateLiteral(['\n  border-top-color: transparent;\n  margin: 0 -10px;\n  font-weight: 600;\n'], ['\n  border-top-color: transparent;\n  margin: 0 -10px;\n  font-weight: 600;\n']),
    _templateObject9 = _taggedTemplateLiteral(['\n  word-wrap: break-word;\n\n  ::first-letter {\n    text-transform: capitalize;\n  }\n'], ['\n  word-wrap: break-word;\n\n  ::first-letter {\n    text-transform: capitalize;\n  }\n']),
    _templateObject10 = _taggedTemplateLiteral(['\n  flex-wrap: wrap;\n  margin: 0 -5px;\n'], ['\n  flex-wrap: wrap;\n  margin: 0 -5px;\n']),
    _templateObject11 = _taggedTemplateLiteral(['\n  position: relative;\n  padding: 10px;\n  padding-right: 24px;\n  background-color: ', ';\n  margin: 4px;\n  border-radius: 4px;\n'], ['\n  position: relative;\n  padding: 10px;\n  padding-right: 24px;\n  background-color: ', ';\n  margin: 4px;\n  border-radius: 4px;\n']),
    _templateObject12 = _taggedTemplateLiteral(['\n  position: absolute;\n  right: 6px;\n  cursor: pointer;\n'], ['\n  position: absolute;\n  right: 6px;\n  cursor: pointer;\n']),
    _templateObject13 = _taggedTemplateLiteral(['\n  cursor: pointer;\n  font-size: 14px;\n  user-select: none;\n  flex-basis: 300px;\n\n  :hover {\n    opacity: 0.8;\n  }\n\n  b {\n    font-weight: 600;\n  }\n'], ['\n  cursor: pointer;\n  font-size: 14px;\n  user-select: none;\n  flex-basis: 300px;\n\n  :hover {\n    opacity: 0.8;\n  }\n\n  b {\n    font-weight: 600;\n  }\n']),
    _templateObject14 = _taggedTemplateLiteral(['\n  padding: 10px 0;\n  text-transform: uppercase;\n  font-size: 14px;\n  font-weight: bold;\n'], ['\n  padding: 10px 0;\n  text-transform: uppercase;\n  font-size: 14px;\n  font-weight: bold;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _recompose = require('recompose');

var _lodash = require('lodash.isequal');

var _lodash2 = _interopRequireDefault(_lodash);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _rgIcons = require('rg-icons');

var _ = require('..');

var _unstable = require('../unstable');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var EnhancedMultiSelectComponent = function (_React$Component) {
  _inherits(EnhancedMultiSelectComponent, _React$Component);

  function EnhancedMultiSelectComponent(props) {
    _classCallCheck(this, EnhancedMultiSelectComponent);

    var _this = _possibleConstructorReturn(this, (EnhancedMultiSelectComponent.__proto__ || Object.getPrototypeOf(EnhancedMultiSelectComponent)).call(this, props));

    _this._getOptionsProps = function () {
      var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _this.props.options;

      return typeof options === 'function' ? options(_this.state) : options;
    };

    _this._getNewSelectedAndOption = function () {
      return _this._getOptionsProps().filter(function (option) {
        return _this.state.selectedState.includes(option.value);
      });
    };

    _this._handleDisplayAll = function () {
      var _this$props = _this.props,
          onChange = _this$props.onChange,
          _this$props$controlle = _this$props.controlled,
          controlled = _this$props$controlle === undefined ? true : _this$props$controlle;


      onChange && onChange({
        selectedValues: [],
        action: 'remove',
        filterType: 'OR'
      });

      if (!controlled) {
        _this._handleRemoveAll();
      }
    };

    _this._handleRemoveAll = function () {
      _this.setState({
        selectedState: [],
        filterTypeValueState: 'OR'
      });
    };

    _this._handleOptionSelected = function (item) {
      var _this$props2 = _this.props,
          onChange = _this$props2.onChange,
          _this$props2$controll = _this$props2.controlled,
          controlled = _this$props2$controll === undefined ? true : _this$props2$controll,
          _this$props2$emptyOnS = _this$props2.emptyOnSelectAll,
          emptyOnSelectAll = _this$props2$emptyOnS === undefined ? true : _this$props2$emptyOnS;
      var _this$state = _this.state,
          selectedState = _this$state.selectedState,
          filterTypeValueState = _this$state.filterTypeValueState;


      var newSelected = [].concat(_toConsumableArray(selectedState), [item.value]);

      // when all item is selected, then empty the value
      if (emptyOnSelectAll && newSelected.length === _this._getOptionsProps().length) {
        onChange && onChange({
          item: item,
          selectedValues: [],
          action: 'add',
          filterType: 'OR'
        });

        if (!controlled) {
          _this._handleRemoveAll();
        }

        return;
      }

      onChange && onChange({
        item: item,
        selectedValues: newSelected,
        action: 'add',
        filterType: filterTypeValueState
      });

      if (!controlled) {
        _this.setState({
          selectedState: newSelected
        });
      }
    };

    _this._handleRemove = function (item) {
      var _this$props3 = _this.props,
          onChange = _this$props3.onChange,
          _this$props3$controll = _this$props3.controlled,
          controlled = _this$props3$controll === undefined ? true : _this$props3$controll;
      var _this$state2 = _this.state,
          selectedState = _this$state2.selectedState,
          filterTypeValueState = _this$state2.filterTypeValueState;


      var newSelected = selectedState.filter(function (selected) {
        return selected !== item.value;
      });

      var filterType = newSelected.length ? filterTypeValueState : 'OR';

      onChange && onChange({
        item: item,
        selectedValues: newSelected,
        action: 'remove',
        filterType: filterType
      });

      if (!controlled) {
        _this.setState({
          selectedState: newSelected,
          filterTypeValueState: filterType
        });
      }
    };

    _this._toggleFilterType = function () {
      var _this$props4 = _this.props,
          _this$props4$controll = _this$props4.controlled,
          controlled = _this$props4$controll === undefined ? true : _this$props4$controll,
          onChange = _this$props4.onChange,
          options = _this$props4.options;
      var _this$state3 = _this.state,
          filterTypeValueState = _this$state3.filterTypeValueState,
          selectedState = _this$state3.selectedState;


      var newFilterValue = filterTypeValueState === 'OR' ? 'AND' : 'OR';
      var newOptions = typeof options === 'function' ? options(_extends({}, _this.state, { filterTypeValueState: newFilterValue })) : options;

      var newselected = newOptions.filter(function (option) {
        return selectedState.includes(option.value);
      }).map(function (option) {
        return option.value;
      });

      onChange && onChange({
        selectedValues: newselected,
        filterType: newFilterValue
      });

      if (!controlled) {
        _this.setState({
          selectedState: newselected,
          filterTypeValueState: newFilterValue
        });
      }
    };

    _this._renderSelectedList = function () {
      var _this$props5 = _this.props,
          _this$props5$withFilt = _this$props5.withFilterType,
          withFilterType = _this$props5$withFilt === undefined ? false : _this$props5$withFilt,
          name = _this$props5.name,
          renderSelectedItems = _this$props5.renderSelectedItems;
      var filterTypeValueState = _this.state.filterTypeValueState;


      var selectedOptions = _this._getNewSelectedAndOption();

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(
          StyledTitleText,
          null,
          'Selected ',
          name
        ),
        React.createElement(
          StyledSelectedItemListFlex,
          null,
          selectedOptions.map(function (item) {
            return renderSelectedItems ? renderSelectedItems({
              item: item,
              handleRemove: function handleRemove() {
                return _this._handleRemove(item);
              }
            }) : React.createElement(
              StyledSelectedItemBox,
              { key: item.value },
              React.createElement(
                _.Span,
                null,
                item.label
              ),
              React.createElement(StyledRemoveIcon, {
                name: 'x',
                size: 12,
                onClick: function onClick() {
                  return _this._handleRemove(item);
                } })
            );
          })
        ),
        withFilterType && React.createElement(
          _.Flex,
          { py: 10 },
          React.createElement(_.Checkbox, {
            controlled: true,
            checked: filterTypeValueState === 'AND',
            onChange: _this._toggleFilterType
          }),
          React.createElement(
            StyledFilterTypeText,
            { onClick: _this._toggleFilterType },
            'Show results with ',
            React.createElement(
              'b',
              null,
              'all selected ',
              name.toLowerCase()
            )
          )
        )
      );
    };

    _this._renderOptionList = function () {
      var _this$props6 = _this.props,
          name = _this$props6.name,
          renderOptionItems = _this$props6.renderOptionItems;

      var options = _this._getOptionsProps().filter(function (option) {
        return !_this.state.selectedState.includes(option.value);
      });

      return React.createElement(
        React.Fragment,
        null,
        React.createElement(
          StyledTitleText,
          null,
          name,
          ' List'
        ),
        React.createElement(
          StyledDisplayAllOptionBox,
          { onClick: function onClick() {
              return _this._handleDisplayAll();
            } },
          React.createElement(
            StyledItemLabelBox,
            null,
            'Display All ',
            name
          )
        ),
        React.createElement(
          StyledOptionsBox,
          null,
          options.map(function (item) {
            return renderOptionItems ? renderOptionItems({
              item: item,
              handleAdd: function handleAdd() {
                return _this._handleOptionSelected(item);
              }
            }) : React.createElement(
              StyledOptionItemBox,
              {
                key: item.value,
                onClick: function onClick() {
                  return _this._handleOptionSelected(item);
                } },
              React.createElement(
                StyledItemLabelBox,
                null,
                item.label
              )
            );
          })
        )
      );
    };

    _this._renderMenu = function () {
      var selectedState = _this.state.selectedState;
      var _this$props7 = _this.props,
          _this$props7$openDire = _this$props7.openDirection,
          openDirection = _this$props7$openDire === undefined ? 'bottom left' : _this$props7$openDire,
          loading = _this$props7.loading,
          open = _this$props7.open,
          options = _this$props7.options;


      if (!open) {
        return null;
      }

      if (loading) {
        return React.createElement(
          StyledMenuBox,
          null,
          React.createElement(
            _.Text,
            { align: 'center', mx: 'auto' },
            React.createElement(
              'em',
              null,
              'Loading...'
            )
          )
        );
      }

      if (!options.length) {
        return React.createElement(
          StyledMenuBox,
          null,
          React.createElement(
            _.Text,
            { align: 'center', mx: 'auto' },
            'No result found'
          )
        );
      }

      return React.createElement(
        StyledMenuBox,
        { mt: '2px', direction: openDirection },
        !!selectedState.length && _this._renderSelectedList(),
        _this._renderOptionList()
      );
    };

    var state = {
      selectedState: props.value,
      filterTypeValueState: props.filterTypeValue || 'OR'
    };
    _this.state = state;
    return _this;
  }

  _createClass(EnhancedMultiSelectComponent, [{
    key: 'componentDidUpdate',
    value: function componentDidUpdate(prevProps) {
      var prevValue = prevProps.value,
          prevOpen = prevProps.open,
          prevFilterTypeValue = prevProps.filterTypeValue;
      var _props = this.props,
          value = _props.value,
          open = _props.open,
          _props$onOpen = _props.onOpen,
          onOpen = _props$onOpen === undefined ? function (data) {} : _props$onOpen,
          _props$onClose = _props.onClose,
          onClose = _props$onClose === undefined ? function (data) {} : _props$onClose,
          filterTypeValue = _props.filterTypeValue;
      var _state = this.state,
          selectedState = _state.selectedState,
          filterTypeValueState = _state.filterTypeValueState;


      if (!(0, _lodash2.default)(prevValue, value)) {
        var _selectedState = value;
        this.setState({ selectedState: _selectedState });
      }

      if (filterTypeValue !== prevFilterTypeValue) {
        this.setState({ filterTypeValueState: filterTypeValue });
      }

      if (open !== prevOpen) {
        open ? onOpen({
          selectedValues: selectedState,
          filterType: filterTypeValueState
        }) : onClose({
          selectedValues: selectedState,
          filterType: filterTypeValueState
        });
      }
    }
  }, {
    key: 'render',
    value: function render() {
      var _props2 = this.props,
          onRef = _props2.onRef,
          open = _props2.open,
          setOpen = _props2.setOpen,
          name = _props2.name;
      var selectedState = this.state.selectedState;


      var selectedInfoText = !selectedState.length ? 'Display all ' + name : selectedState.length + ' ' + name + ' selected';

      return React.createElement(
        'div',
        { ref: onRef, style: { width: '100%' } },
        React.createElement(
          _.Box,
          { is: _.Relative },
          React.createElement(
            StyledInputBoxWrapper,
            null,
            React.createElement(
              StyledInputFlex,
              {
                onClick: function onClick() {
                  setOpen(!open);
                },
                active: open ? 'true' : undefined },
              selectedInfoText
            ),
            React.createElement(
              StyledCaretIconFlex,
              { alignItems: 'center', justify: 'center', px: 2 },
              React.createElement(StyledArrowSVG, null)
            )
          ),
          this._renderMenu()
        )
      );
    }
  }]);

  return EnhancedMultiSelectComponent;
}(React.Component);

var StyledCaretIconFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject, function (props) {
  return props.theme.colors.border;
});

var StyledArrowSVG = (0, _styledComponents2.default)(function (props) {
  return React.createElement(
    'svg',
    _extends({}, props, { viewBox: '0 0 32 32' }),
    React.createElement('path', { d: 'M0 6 L32 6 L16 28 z' })
  );
})(_templateObject2, function (props) {
  return props.theme.colors.border;
});

var StyledInputBoxWrapper = (0, _styledComponents2.default)(_.Box)(_templateObject3);

var StyledInputFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject4, function (_ref) {
  var theme = _ref.theme,
      active = _ref.active;
  return active === 'true' ? theme.colors.havelockBlue : theme.colors.alto;
});

var StyledMenuBox = (0, _styledComponents2.default)(_.Box)(_templateObject5, function (_ref2) {
  var direction = _ref2.direction;
  return direction === 'top left' || direction === 'top right' ? '40px' : undefined;
}, function (_ref3) {
  var direction = _ref3.direction;
  return direction === 'bottom left' || direction === 'bottom right' ? '40px' : undefined;
}, function (_ref4) {
  var direction = _ref4.direction;
  return direction === 'top left' || direction === 'bottom left' ? '0' : undefined;
}, function (_ref5) {
  var direction = _ref5.direction;
  return direction === 'bottom right' || direction === 'bottom right' ? '0' : undefined;
}, function (props) {
  return props.theme.colors.white;
}, function (_ref6) {
  var theme = _ref6.theme;
  return theme.colors.alto;
});

var StyledOptionsBox = (0, _styledComponents2.default)(_.Box)(_templateObject6);

var StyledOptionItemBox = (0, _styledComponents2.default)(_.Flex)(_templateObject7, function (_ref7) {
  var theme = _ref7.theme;
  return theme.colors.lightGrey;
}, function (_ref8) {
  var theme = _ref8.theme;
  return theme.colors.lightGrey;
});

var StyledDisplayAllOptionBox = (0, _styledComponents2.default)(StyledOptionItemBox)(_templateObject8);

var StyledItemLabelBox = (0, _styledComponents2.default)(_.Box)(_templateObject9);

var StyledSelectedItemListFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject10);

var StyledSelectedItemBox = (0, _styledComponents2.default)(_.Box)(_templateObject11, function (_ref9) {
  var theme = _ref9.theme;
  return theme.colors.concrete;
});

var StyledRemoveIcon = (0, _styledComponents2.default)(_rgIcons.Icon)(_templateObject12);

var StyledFilterTypeText = (0, _styledComponents2.default)(_.Text)(_templateObject13);

var StyledTitleText = (0, _styledComponents2.default)(_.Text)(_templateObject14);

var EnhancedMultiSelect = (0, _recompose.compose)(_unstable.withToggle, (0, _recompose.withProps)(function (_ref10) {
  var setOpen = _ref10.setOpen,
      open = _ref10.open;
  return {
    onClickOutside: function onClickOutside() {
      return open && setOpen(false);
    }
  };
}), _unstable.withClickOutside)(EnhancedMultiSelectComponent);

exports.EnhancedMultiSelect = EnhancedMultiSelect;