'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _createClass = function () { function defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, descriptor.key, descriptor); } } return function (Constructor, protoProps, staticProps) { if (protoProps) defineProperties(Constructor.prototype, protoProps); if (staticProps) defineProperties(Constructor, staticProps); return Constructor; }; }();

var _templateObject = _taggedTemplateLiteral(['\n  padding-left: 30px;\n'], ['\n  padding-left: 30px;\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  z-index: 1000;\n  cursor: pointer;\n  min-width: 400px;\n  max-height: 200px;\n  overflow: auto;\n'], ['\n  z-index: 1000;\n  cursor: pointer;\n  min-width: 400px;\n  max-height: 200px;\n  overflow: auto;\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  &:nth-child(even) {\n    background-color: ', ';\n    box-shadow: 0 -1px 0 0 ', ',\n      0 1px 0 0 ', ';\n  }\n\n  :hover {\n    background-color: ', ';\n  }\n\n  min-width: 400px;\n'], ['\n  &:nth-child(even) {\n    background-color: ', ';\n    box-shadow: 0 -1px 0 0 ', ',\n      0 1px 0 0 ', ';\n  }\n\n  :hover {\n    background-color: ', ';\n  }\n\n  min-width: 400px;\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  text-transform: capitalize;\n'], ['\n  text-transform: capitalize;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _recompose = require('recompose');

var _withToggle = require('../unstable/withToggle');

var _withToggle2 = _interopRequireDefault(_withToggle);

var _withClickOutside = require('../unstable/withClickOutside');

var _withClickOutside2 = _interopRequireDefault(_withClickOutside);

var _rgIcons = require('rg-icons');

var _ = require('../');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }

function _possibleConstructorReturn(self, call) { if (!self) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return call && (typeof call === "object" || typeof call === "function") ? call : self; }

function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function, not " + typeof superClass); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, enumerable: false, writable: true, configurable: true } }); if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass; }

var AutoComplete = function (_React$Component) {
  _inherits(AutoComplete, _React$Component);

  function AutoComplete() {
    var _ref;

    var _temp, _this, _ret;

    _classCallCheck(this, AutoComplete);

    for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
      args[_key] = arguments[_key];
    }

    return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = AutoComplete.__proto__ || Object.getPrototypeOf(AutoComplete)).call.apply(_ref, [this].concat(args))), _this), _this.state = {
      keyword: '',
      searchResult: [],
      isOpen: false
    }, _this._onClick = function () {
      var _this$props = _this.props,
          open = _this$props.open,
          setOpen = _this$props.setOpen;

      setOpen(!open);
    }, _this._onClickOutside = function () {
      _this.props.setOpen(false);
    }, _this._handleOnClick = function (value, label) {
      var _this$props2 = _this.props,
          name = _this$props2.name,
          onChange = _this$props2.onChange;


      _this.setState({ keyword: label });
      onChange(name, value);
    }, _this._handleOnchange = function (e) {
      var data = _this.props.data;

      var keyword = e.target.value;

      var searchResult = keyword !== '' ? data.filter(function (item) {
        return !!item.label.toLowerCase().includes(keyword);
      }) : [];
      _this.setState({ keyword: keyword, searchResult: searchResult });
    }, _this._renderResult = function () {
      var _this$state = _this.state,
          keyword = _this$state.keyword,
          searchResult = _this$state.searchResult;
      var colors = _.theme.colors;


      return React.createElement(
        StyledAutoCompleteResultBox,
        { bg: colors.white, is: _.Absolute, mt: '2px' },
        searchResult.map(function (item) {
          var currentSplitLength = 0;

          return React.createElement(
            StyledResultFlex,
            {
              py: '10px',
              px: '20px',
              onClick: function onClick() {
                return _this._handleOnClick(item.value, item.label);
              },
              key: item.value },
            item.label.toLowerCase().split(keyword).map(function (charSplit, charIndex) {
              var itemLabel = item.label;
              var itemSplitKeywordLength = itemLabel.toLowerCase().split(keyword).length;
              var itemSplitLength = itemLabel.split('').length;
              var keywordLength = keyword.split('').length;

              var charSplitLength = charSplit.split('').length;
              var currSplitLength = currentSplitLength;
              var endSplitLength = currSplitLength + charSplitLength;
              var endSplitKeyLength = endSplitLength + keywordLength;
              currentSplitLength = currentSplitLength + charSplitLength + keywordLength;

              return React.createElement(
                _.Flex,
                { key: charSplit + String(charIndex) },
                React.createElement(
                  _.Flex,
                  null,
                  itemLabel.charAt(currSplitLength) === ' ' && React.createElement(
                    _.Text,
                    null,
                    '\xA0'
                  )
                ),
                React.createElement(
                  _.Flex,
                  null,
                  itemLabel.substring(currSplitLength, endSplitLength)
                ),
                React.createElement(
                  _.Flex,
                  null,
                  itemLabel.charAt(endSplitLength - 1) === ' ' && React.createElement(
                    _.Text,
                    null,
                    '\xA0'
                  )
                ),
                React.createElement(
                  _.Flex,
                  { bg: colors.green },
                  itemLabel.substring(endSplitLength, endSplitKeyLength)
                )
              );
            })
          );
        })
      );
    }, _temp), _possibleConstructorReturn(_this, _ret);
  }

  _createClass(AutoComplete, [{
    key: 'componentDidMount',
    value: function componentDidMount() {
      var _props = this.props,
          value = _props.value,
          data = _props.data;

      var keyword = value ? data.filter(function (item) {
        return item.value === value;
      })[0].label : '';

      this.setState({ keyword: keyword });
    }
  }, {
    key: 'render',
    value: function render() {
      var _this2 = this;

      var _state = this.state,
          keyword = _state.keyword,
          searchResult = _state.searchResult;
      var _props2 = this.props,
          name = _props2.name,
          placeholder = _props2.placeholder,
          value = _props2.value,
          open = _props2.open,
          multiple = _props2.multiple;


      var resultFound = !!searchResult && Array.isArray(searchResult) && searchResult.length > 0;

      return React.createElement(
        'div',
        { ref: this.props.onRef },
        React.createElement(
          _.Flex,
          { onClick: this._onClick },
          React.createElement(
            _.Box,
            { w: this.props.width },
            React.createElement(
              _.Relative,
              null,
              React.createElement(
                _.Absolute,
                { left: '0.625rem', top: '52%', transform: 'translateY(-50%)' },
                React.createElement(_rgIcons.Icon, { name: 'search' })
              ),
              React.createElement(StyledInput, {
                type: 'text',
                name: name,
                value: !!multiple ? '' : keyword,
                placeholder: placeholder,
                onChange: function onChange(e) {
                  _this2._handleOnchange(e);
                },
                autoComplete: 'off'
              }),
              !!resultFound && !!open && this._renderResult()
            )
          )
        )
      );
    }
  }]);

  return AutoComplete;
}(React.Component);

var StyledInput = (0, _styledComponents2.default)(_.Input)(_templateObject);

var StyledAutoCompleteResultBox = (0, _styledComponents2.default)(_.Box)(_templateObject2);

var StyledResultFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject3, function (props) {
  return props.theme.colors.softBrown;
}, function (props) {
  return props.theme.colors.alto;
}, function (props) {
  return props.theme.colors.alto;
}, function (props) {
  return props.theme.colors.greySmoke;
});

var StyledCapitalizeText = (0, _styledComponents2.default)(_.Text)(_templateObject4);

exports.default = (0, _recompose.compose)(_withToggle2.default, (0, _recompose.withProps)(function (_ref2) {
  var setOpen = _ref2.setOpen;
  return {
    onClickOutside: function onClickOutside() {
      return setOpen(false);
    }
  };
}), _withClickOutside2.default)(AutoComplete);