'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  height: 80px;\n  border-bottom: 1px solid ', ';\n'], ['\n  height: 80px;\n  border-bottom: 1px solid ', ';\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  cursor: pointer;\n'], ['\n  cursor: pointer;\n']);

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ = require('..');

var _rgIcons = require('rg-icons');

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _util = require('../util');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var ModalHeader = function ModalHeader(_ref) {
  var onClose = _ref.onClose,
      title = _ref.title,
      content = _ref.content;
  return React.createElement(
    StyledHeaderFlex,
    {
      justifyContent: 'space-between',
      w: 1,
      bg: '#f1f4f8',
      alignItems: 'center',
      px: 4 },
    React.createElement(
      StyledCloseButtonFlex,
      { alignItems: 'center', onClick: onClose },
      React.createElement(_rgIcons.Icon, { name: 'close', size: '20px' }),
      React.createElement(
        _.Text,
        { ml: 2 },
        'Close'
      )
    ),
    React.createElement(
      _.Heading,
      null,
      title
    ),
    React.createElement(
      _.Box,
      null,
      content && content
    )
  );
};

var StyledHeaderFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject, function (props) {
  return (0, _util.color)(props)('blue');
});

var StyledCloseButtonFlex = (0, _styledComponents2.default)(_.Flex)(_templateObject2);

exports.default = ModalHeader;