'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _templateObject = _taggedTemplateLiteral(['\n  display: none;\n'], ['\n  display: none;\n']),
    _templateObject2 = _taggedTemplateLiteral(['\n  padding: 0;\n  width: 100%;\n  position: relative;\n'], ['\n  padding: 0;\n  width: 100%;\n  position: relative;\n']),
    _templateObject3 = _taggedTemplateLiteral(['\n  width: 100%;\n  margin-top: 0.3125rem;\n  border-radius: 0.25rem;\n  pointer-events: none;\n'], ['\n  width: 100%;\n  margin-top: 0.3125rem;\n  border-radius: 0.25rem;\n  pointer-events: none;\n']),
    _templateObject4 = _taggedTemplateLiteral(['\n  width: 7.5rem;\n  padding: 0.5rem 0;\n  text-align: center;\n  position: absolute;\n  right: 0;\n  top: 0.3rem;\n  bottom: 0;\n  background-color: ', ';\n  color: ', ';\n  border-top-right-radius: 0.25rem;\n  border-bottom-right-radius: 0.25rem;\n  cursor: pointer;\n'], ['\n  width: 7.5rem;\n  padding: 0.5rem 0;\n  text-align: center;\n  position: absolute;\n  right: 0;\n  top: 0.3rem;\n  bottom: 0;\n  background-color: ', ';\n  color: ', ';\n  border-top-right-radius: 0.25rem;\n  border-bottom-right-radius: 0.25rem;\n  cursor: pointer;\n']);

var _react = require('react');

var _react2 = _interopRequireDefault(_react);

var _styledComponents = require('styled-components');

var _styledComponents2 = _interopRequireDefault(_styledComponents);

var _ = require('../');

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _taggedTemplateLiteral(strings, raw) { return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

var InputFile = function InputFile(_ref) {
  var placeholder = _ref.placeholder,
      value = _ref.value,
      name = _ref.name,
      onChange = _ref.onChange,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === undefined ? true : _ref$disabled,
      accept = _ref.accept;
  return _react2.default.createElement(
    _.Flex,
    { flexDirection: 'row', justifyContent: 'space-evenly', mb: 0 },
    _react2.default.createElement(
      Label,
      { htmlFor: name },
      _react2.default.createElement(File, {
        type: 'file',
        mb: 3,
        name: name,
        id: name,
        onChange: onChange,
        accept: accept
      }),
      _react2.default.createElement(TextLabel, { type: 'text', value: placeholder || value, readOnly: disabled }),
      _react2.default.createElement(
        Browse,
        null,
        'BROWSE'
      )
    )
  );
};

var File = (0, _styledComponents2.default)(_.Input)(_templateObject);

var Label = _styledComponents2.default.label(_templateObject2);

var TextLabel = (0, _styledComponents2.default)(_.Input)(_templateObject3);

var Browse = _styledComponents2.default.div(_templateObject4, function (props) {
  return props.theme.colors.dustyGray30;
}, function (props) {
  return props.theme.colors.gray;
});

exports.default = InputFile;