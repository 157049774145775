'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});

var _react = require('react');

var React = _interopRequireWildcard(_react);

var _ = require('..');

function _interopRequireWildcard(obj) { if (obj && obj.__esModule) { return obj; } else { var newObj = {}; if (obj != null) { for (var key in obj) { if (Object.prototype.hasOwnProperty.call(obj, key)) newObj[key] = obj[key]; } } newObj.default = obj; return newObj; } }

var Avatar = function Avatar(_ref) {
  var image = _ref.image;

  return React.createElement(
    _.Box,
    null,
    React.createElement(
      'svg',
      { viewBox: '0 0 65 70', width: '65', height: '70' },
      React.createElement(
        'defs',
        null,
        React.createElement(
          'pattern',
          { id: 'img', width: '1', height: '1' },
          React.createElement('image', {
            href: image,
            width: '80',
            height: '80',
            x: '50%',
            y: '50%',
            transform: 'translate(-38,-40)'
          })
        )
      ),
      React.createElement(
        'g',
        { transform: 'scale(0.7)' },
        React.createElement('path', {
          stroke: 'white',
          fill: 'url(#img)',
          strokeWidth: '4',
          d: 'M43.8,13.3l-22.6,13c-3.8,2.2-6.2,6.3-6.2,10.7v26.1c0,4.4,2.4,8.5,6.2,10.7l22.6,13c3.8,2.2,8.6,2.2,12.4,0l22.6-13   c3.8-2.2,6.2-6.3,6.2-10.7V37.1c0-4.4-2.4-8.5-6.2-10.7l-22.6-13C52.4,11,47.6,11,43.8,13.3z'
        })
      )
    )
  );
};

exports.default = Avatar;